import React from 'react'

export interface GeneralContextInterface {
  runLoading: () => void
  stopLoading: () => void
  toggleLanguage: () => void
  language: string
  toggleMenu: (toogle: boolean) => void
  toggleContact: (toggle: boolean) => void
  headerColor: string
  handleHeaderColor: (color: string) => void
}

export default React.createContext<GeneralContextInterface>(
  {} as GeneralContextInterface
)
