import firebase from './Firebase'

export const ERROR = "ERROR"
export const OK = "OK"

const functions = firebase.functions()
// functions.useEmulator('localhost', 5001)

export const llamarCloudFunction = async (name: string, datos: any) => {
  const callable = functions.httpsCallable(name)
  try {
    const res = await callable(datos)
    return { estado: OK, res }
  } catch (error) {
    return { estado: ERROR, error }
  }
}