import { ERROR, OK } from '../proxy/FirebaseFunctions'
import IContact from '../screens/general/contact/IContact'
import { llamarCloudFunction } from './../proxy/FirebaseFunctions'

export const solicitudContacto = async (formulario: IContact) => {
  const response = await llamarCloudFunction('enviar', formulario)
  if (response.estado === ERROR) {
    return ERROR
  }
  return OK
}
