import { useContext } from 'react'
import GeneralContext from '../generalContext/GeneralContext'
import { buttonBanner } from './data'

const Banner = ({ bgImage, title }: { bgImage: string; title: string }) => {
  const { toggleContact, language } = useContext(GeneralContext)

  return (
    <div
      className="bg-center bg-cover bg-no-repeat w-full h-[409px] sm:h-[480px] pt-[177px] sm:pt-[198px] lg:pt-[393px] pb-[44px] sm:pb-[70px] lg:pb-[32px] px-8 sm:px-[100px]"
      style={{ backgroundImage: 'url("' + bgImage + '")' }}
    >
      <div className="max-w-[1500px] mx-auto flex flex-col lg:flex-row items-center justify-between w-full h-full">
        <h1 className="uppercase font-town font-extrabold text-[28px] sm:text-[46px] tracking-[0.15em] text-center text-white leading-[34px] sm:leading-[55px]">
          {title}
        </h1>
        <span
          className="btn-primary text-white border-white hover:bg-white hover:text-black"
          onClick={() => toggleContact(true)}
        >
          {buttonBanner.button[language].toUpperCase()}
        </span>
      </div>
    </div>
  )
}

export default Banner
