import React from 'react'
import { GeneralContextInterface } from '../../general/generalContext/GeneralContext'
import { titleValue, values, IValues } from '../data'

const Values = ({ language }: GeneralContextInterface | any) => {
  return (
    <div className="w-10/12 lg:w-11/12 relative bg-greyBg container m-auto">
      <div>
        <h2 className="text-2xl font-town lg:text-3xl tracking-widest font-semibold text-center uppercase">
          {titleValue[language]}
        </h2>
      </div>
      <div className="flex justify-center items-center flex-wrap place-items-center w-full mx-auto gap-12 mt-14">
        {values.map((value: IValues) => {
          return (
            <div
              className="max-w-[380px] lg:max-w-full sm:w-full place-self-center lg:w-[382px] h-full md:h-[285px] flex flex-col justify-center items-center space-y-6 rounded-lg mb-5 hover:scale-105 transition duration-300 py-10 md:py-16 px-7 "
              key={value.id}
              style={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}
            >
              <div className="w-3/12 grid place-items-center">
                <img src={value.image} alt={value.title} />
              </div>
              <h3 className="text-base font-bold tracking-widest">
                {value.title?.[language]}
              </h3>
              <p className="text-sm font-thin leading-7 text-center">
                {value.paragraph?.[language]}
              </p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Values
