import { firstPrestige } from '../data'

const FirstPrestigeSection = ({ language }: any) => {
  return (
    <div className="container m-auto py-[60px]">
      <div className="w-[70%] m-auto flex flex-col justify-center items-center  space-y-10">
        <h2 className="font-town text-[30px] font-bold tracking-[0.15em]">
          {firstPrestige.headline?.[language]}
        </h2>
        <p className="text-center text-[16px] tracking-[0.04em] font-segoe font-thin">
          {firstPrestige.paragraph?.[language]}
        </p>
      </div>
      <div className="w-11/12 sm:w-10/12 max-w-full overflow-x-auto scrollbar scrollbar-track scrollbar-thumb flex xl:justify-center mt-32 m-auto gap-16 pb-8">
        {firstPrestige.info.map((card) => (
          <div
            key={card.id}
            className=" sm:w-[255px] flex flex-col justify-center items-center gap-x-10 gap-y-5 text-center ml-6"
          >
            <img
              className="min-w-0 max-w-[250px] max-h-56 rounded-lg object-contain bg-no-repeat h-[50%]"
              src={card.image}
              alt=""
            />
            <div className="w-full flex flex-col font-segoe">
              <p className="font-thin tracking-[0.04em] text-[16px]">
                {card.text?.[language]}
                {card.id === 2 && (
                  <>
                    <br />
                    <a
                      href="https://app.clustermantenimientoctg.com/"
                      target="_blank"
                      rel="noreferrer"
                      className="break-words"
                    >
                      <b>(https://app.clustermantenimientoctg.com/)</b>
                    </a>
                  </>
                )}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default FirstPrestigeSection
