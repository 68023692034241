import ABB from '../../../assets/images/ABB.png'
import Baldor from '../../../assets/images/Baldor.jpeg'
import GeneralElectrics from '../../../assets/images/General-electric.png'
import Hyosung from '../../../assets/images/HYOSUNG.jpg'
import Lesson from '../../../assets/images/Leeson.png'
import LouisAllis from '../../../assets/images/Louis-Allis.jpg'
import MarelliMotri from '../../../assets/images/MarelliMotori.png'
import RelianceElectric from '../../../assets/images/Reliance-Electric.jpg'
import Siemens from '../../../assets/images/Siemens.png'
import TecowestingHouse from '../../../assets/images/Tecowestinghouse .png'
import Toshiba from '../../../assets/images/TOSHIBA.jpeg'
import WardLeonard from '../../../assets/images/Ward-Leonard.png'
import WEG from '../../../assets/images/WEG.jpeg'
import WorldWide from '../../../assets/images/Worldwide.png'

import ourWorkersThree from '../../../assets/images/quality/nuestro-equipo-3.jpg'
import ourWorkersFour from '../../../assets/images/quality/nuestro-equipo-5.jpg'

import TeamOne from '../../../assets/images/quality/equipo-1.jpg'
import TeamTwo from '../../../assets/images/quality/equipo-2.jpg'
import TeamThree from '../../../assets/images/quality/equipo-3.jpg'

import materials from '../../../assets/images/quality/materiales.jpg'

import ANSI from '../../../assets/images/quality/ANSI.png'
import ASTM from '../../../assets/images/quality/ASTM.png'
import IEC from '../../../assets/images/quality/IEC.png'
import IEE from '../../../assets/images/quality/IEEE.png'
import NEMA from '../../../assets/images/quality/NEMA.png'

// Fabricantes

export type intro = {
  title: { es: string; en: string } | any
  description?: { es: string; en: string } | any
  titleTwo?: { es: string; en: string } | any
}

export const introQuality: intro = {
  title: {
    es: 'Calidad',
    en: 'Quality'
  },
  description: {
    es: 'La empresa ha sido designada como servicio autorizado por firmas fabricantes de motores y generadores por la cual contamos con asesoría directa ofreciendo completa confiabilidad en las reparaciones con respecto a conservar estándares y diseños originales de los equipos, suministro de repuestos e innovaciones tecnológicas.',
    en: 'The company has been designated as authorized service by manufacturers of motors and generators by which we are in contact with full reliability in repairs with respect to maintaining standards and original designs of the equipment, supply of spare parts and technological innovations.'
  },
  titleTwo: {
    es: 'Fabricantes',
    en: 'Manufacturers'
  }
}

interface ICompanies {
  id: number
  image: string
  title?: string
}

export const companies: ICompanies[] = [
  {
    id: 1,
    image: ABB,
    title: 'ABB'
  },
  {
    id: 2,
    image: Baldor,
    title: 'Baldor'
  },
  {
    id: 3,
    image: GeneralElectrics,
    title: 'General Electrics'
  },
  {
    id: 4,
    image: Hyosung,
    title: 'HYOSUNG'
  },
  {
    id: 5,
    image: Lesson,
    title: 'Lesson'
  },
  {
    id: 6,
    image: LouisAllis,
    title: 'Louis Allis'
  },
  {
    id: 7,
    image: MarelliMotri,
    title: 'Marelli Morti'
  },
  {
    id: 8,
    image: RelianceElectric,
    title: 'Reliance Electric'
  },
  {
    id: 9,
    image: Siemens,
    title: 'Siemens'
  },
  {
    id: 10,
    image: TecowestingHouse,
    title: 'Tecowesting House'
  },
  {
    id: 11,
    image: Toshiba,
    title: 'TOSHIBA'
  },
  {
    id: 13,
    image: WardLeonard,
    title: 'Ward Leonard'
  },
  {
    id: 14,
    image: WEG,
    title: 'WEG'
  },
  {
    id: 13,
    image: WorldWide,
    title: 'WorldWide'
  }
]

// Sections

interface ISection {
  left: boolean
  img: string[]
  title: { es: string; en: string }
  description: { es: string; en: string }
}

export const sectionOne: ISection = {
  left: true,
  img: [ourWorkersThree, ourWorkersFour],
  title: { es: 'Nuestro equipo de trabajo', en: 'Our team' },
  description: {
    es: 'Nuestro equipo de trabajo es nuestro motor, el compromiso de toda una familia que busca optimizar resultados en los servicios que ofrecemos con la mayor disposición y esfuerzo. ',
    en: 'Our team is our engine, the commitment of all a family that seeks to optimize results in the services we offer with the greatest enthusiasm and effort.'
  }
}

export const sectionTwo: ISection = {
  left: false,
  img: [TeamOne, TeamTwo, TeamThree],
  title: { es: 'Nuestros equipos de pruebas', en: 'Our test teams' },
  description: {
    es: 'Cada máquina que ingresa a LKS es analizada de manera exhaustiva tanto en su parte eléctrica como en su parte mecánica, para garantizar al cliente un funcionamiento óptimo cumpliendo con las especificaciones determinadas en la placa del equipo. De esto queda constancia escrita en los protocolos e informes finales de pruebas después que se entregan después de cada intervención.',
    en: 'Each machine that enters LKS is analyzed exhaustively both in its electrical and mechanical part, to guarantee to the client an optimal functioning satisfying the specifications determined on the board of the equipment. From this it is written evidence in the protocols and final reports of tests after they are delivered after each intervention.'
  }
}
export const sectionThree: ISection = {
  left: true,
  img: [materials],
  title: { es: 'Materiales', en: 'Materials' },
  description: {
    es: 'Todos los insumos empleados en el mantenimiento y reparación de los motores y equipos, cumplen con las normas técnicas dictadas por las empresas fabricantes, apoyadas por las respectivas fichas técnicas y de seguridad en el manejo de estos materiales.',
    en: 'All the materials employed in the maintenance and repair of the motors and equipment, comply with the technical standards dictated by the manufacturers, supported by the respective technical specifications and safety in the handling of these materials.'
  }
}

// Normativa

interface INormative {
  id: number
  img: string
  description: string
}

type Normative = {
  title?: { es: string; en: string } | any
  description?: { es: string; en: string } | any
}

export const textNormative: Normative = {
  title: {
    es: 'Normativa',
    en: 'Normative'
  },
  description: {
    es: ' Todos nuestros trabajos se realizan bajo normas estandarizadas nacionales e internacionales',
    en: 'All our works are carried out under standardized national and international standards'
  }
}

export const cardNormative: INormative[] = [
  {
    id: 1,
    img: ANSI,
    description: 'American National Standard Institute (ANSI)'
  },
  {
    id: 2,
    img: IEE,
    description: 'Institute of Elecrical and Electronic Enegineers (IEEE)'
  },
  {
    id: 3,
    img: NEMA,
    description: 'National Elecrical Code of Manufacturers'
  },
  {
    id: 4,
    img: IEC,
    description: 'International Electrotechnical Comission'
  },
  {
    id: 5,
    img: ASTM,
    description: 'American Society for Testing and Materials '
  },
  {
    id: 6,
    img: ASTM,
    description: 'RETIE '
  }
]
