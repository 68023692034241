import lupa from '../../../assets/lupa_1.svg'
import shakeHands from '../../../assets/apreton-de-manos1.svg'
import divisa from '../../../assets/divisa_1.svg'
import atom from '../../../assets/atomo_1.svg'
import heart from '../../../assets/corazon_1.svg'
import mision from '../../../assets/images/security/mision.jpg'
import vision from '../../../assets/images/security/vision.jpg'
import hsq from '../../../assets/images/security/hseq.jpg'

export interface IState {
  title: { es: string; en: string } | any
  headingTwo: { es: string; en: string } | any
  paragraph: { es: string; en: string } | any
}

export const firstSection: IState = {
  title: {
    es: 'Seguridad',
    en: 'Security'
  },
  headingTwo: {
    es: 'La seguridad es uno de nuestros pilares',
    en: 'Security is one of our pillars'
  },
  paragraph: {
    es: 'Estamos comprometidos con la satisfacción, el bienestar, la salud y el cumplimiento de los requerimientos de nuestros clientes, de nuestro equipo de trabajo y de quienes participan en los procesos de conservación del medio ambiente.',
    en: 'We are committed to the satisfaction, well-being, health and compliance with the requirements of our customers, our team and those who participate in the preservation of the environment.'
  }
}

// Values

export interface IValues {
  id: number
  image: string
  title?: { es: string; en: string } | string | any
  paragraph?: { es: string; en: string } | string | any
}

export const titleValue: { es: string; en: string } | any = {
  es: 'Valores',
  en: 'Values'
}

export const values: IValues[] = [
  {
    id: 1,
    image: lupa,
    title: { es: 'Transparencia', en: 'Transparency' },
    paragraph: {
      es: ' Tenemos la claridad en la comunicación de nuestro entorno de trabajo.',
      en: 'We have clear communication in our workplace environment.'
    }
  },
  {
    id: 2,
    image: shakeHands,
    title: { es: 'Compromiso', en: 'Compromise' },
    paragraph: {
      es: ' Trabajar siempre con sentido colectivo, compartiendo y disfrutando la diversidad.',
      en: 'Work always with collective sense, sharing and enjoying diversity.'
    }
  },
  {
    id: 3,
    image: divisa,
    title: { es: 'Excelencia', en: 'Excellence' },
    paragraph: {
      es: ' Nos motiva la calidad y la mejora permanente.',
      en: 'We motivate the quality and the permanent improvement.'
    }
  },
  {
    id: 4,
    image: atom,
    title: { es: 'Innovación', en: 'Innovation' },
    paragraph: {
      es: 'Soñamos con una organización formada por personas que permanentemente evalúan la forma de impactar en la cadena de valor de acuerdo a la economía y las tendencias tecnológicas globales.',
      en: 'We imagine an organization formed by people who constantly evaluate the way to impact in the value chain according to the global economy and technological trends.'
    }
  },
  {
    id: 5,
    image: heart,
    title: { es: 'Vocación', en: 'Vocation' },
    paragraph: {
      es: 'Nos mueve entender y atender las necesidades de nuestros clientes, nuestros aliados y nuestros colaboradores.',
      en: 'We move to understand and to answer the needs of our customers, our partners and our collaborators.'
    }
  }
]

// Mision Mision

interface IContext {
  image: string
  title?: { es: string; en: string } | string | any
  paragraph: { es: string; en: string } | string | any
}

export const textMision: IContext = {
  image: mision,
  title: {
    es: 'Misión',
    en: 'Mission'
  },
  paragraph: {
    es: 'Nuestra misión es ser el aliado estratégico de alto valor para las compañías industriales en las áreas del mantenimiento predictivo, preventivo y correctivo de maquinaria eléctrica, rotativa y estacionaria. Para ello, aportamos la experiencia de nuestro equipo humano y aplicamos las normativas y altos estándares de fabricación, utilizando la última tecnología disponible y preservando el medio ambiente con responsabilidad.',
    en: 'Our mission is to be the strategic ally of high value for industrial companies in the maintenance predictive, preventive and corrective areas of electrical machinery, rotary and stationary. To this end, we contribute our human resources and apply the latest technology and high standards of manufacturing, using the latest technology and preserving the environment with responsibility.'
  }
}
// Mision Vision

export const textVision: IContext = {
  image: vision,
  title: {
    es: 'Visión',
    en: 'Vision'
  },
  paragraph: {
    es: 'Nuestra empresa será la organización más innovadora en los próximos diez años. Mediante el uso de tecnología de punta para realizar el mantenimiento y reparación de equipos eléctricos, contribuiremos con la evolución y el crecimiento industrial. Así mismo, emplearemos personas comprometidas para cuidar nuestro entorno con responsabilidad en beneficio del progreso empresarial del país.',
    en: 'Our company will be the most innovative in the next ten years. Through the use of the latest technology to perform maintenance and repair of electrical equipment, we will contribute with the evolution and growth of industrial. In addition, we will employ people committed to care for our environment with responsibility in the benefit of the corporate progress of the country.'
  }
}
// Mision HSQ

export const textHSQ: IContext = {
  image: hsq,
  title: {
    es: 'Política HSQ',
    en: 'HSQ Policy'
  },
  paragraph: {
    es: 'LKS es una empresa colombiana dedicada a la prestación de servicios de mantenimiento, reparación de equipos rotativos y gerencia de proyectos; está comprometida con el cumplimiento y satisfacción de los requerimientos de los clientes, el bienestar y salud de sus trabajadores y quienes participan en los procesos que involucran la conservación del medio ambiente. Cumpliendo con los requisitos legales, reglamentarios y los definidos por la organización; implementando la seguridad industrial, controlando los riesgos ocupacionales mecánicos, eléctricos, locativos, químicos, ergonómicos para evitar accidentes y enfermedades profesionales, para así mejorar la calidad de vida de los empleados; y minimizando el impacto ambiental inherente a sus operaciones. Con la participación de personal competente enfocado a la mejora continua del Sistema de Gestión de Calidad, Seguridad industrial, Salud Ocupacional y protección del Medio Ambiente.',
    en: 'LKS is a Colombian company dedicated to the provision of maintenance, repair of rotary equipment and management of projects; committed to the fulfillment and satisfaction of the requirements of the customers, the wellbeing and health of their employees and those who participate in the processes involving the preservation of the environment. Fulfilling with the legal, regulatory and defined by the organization; implementing the industrial safety, controlling occupational mechanical, electrical, local, chemical, ergonomic risks to avoid accidents and professional diseases, in order to improve the quality of life of employees; and minimizing the environmental impact inherent to its operations. With the participation of competent personnel focused on the continuous improvement of the Quality Management System, Industrial Safety, Occupational Health and Environmental Protection.'
  }
}
