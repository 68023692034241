import React from 'react'
import Banner from '../../general/banner/Banner'
import prestigeBanner from '../../../assets/images/prestige/prestigePortada.png'
import { titlePrestige } from '../data'

const IntroPrestige = ({ language }: any) => {
  return (
    <div>
      <Banner bgImage={prestigeBanner} title={titlePrestige.title[language]} />
    </div>
  )
}

export default IntroPrestige
