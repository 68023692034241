import firebase from './Firebase'

const storage = firebase.storage()

export const getDownloadUrl = async (url: string): Promise<string | undefined> => {
  try {
    const gsReference = storage.refFromURL(url)
    const urlDownload = await gsReference.getDownloadURL()
    console.log(urlDownload)
    return urlDownload
  } catch (error) {
    console.log(error)
  }
}
