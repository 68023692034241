import { useContext, useEffect, useRef, useState } from 'react'
import GeneralContext from '../../general/generalContext/GeneralContext'

const HeroSection = ({ left, img, description, title }: any) => {
  const { language } = useContext(GeneralContext)
  const [position, setPosition] = useState(0)
  const timerRef = useRef<any>(null)

  const handleMove = (left: any): void => {
    if (left && position > 0) setPosition((position - 1) % img.length)
    else if (left && position === 0) setPosition(img.length - 1)
    else if (!left) setPosition((position + 1) % img.length)
  }
  useEffect(() => {
    timerRef.current = setInterval(() => {
      setPosition((prevState) => (prevState + 1) % img.length)
    }, 3000)
    return () => {
      clearInterval(timerRef.current)
    }
  }, [position])

  return (
    <section className={!left ? 'bg-white w-full' : 'w-full'}>
      <div
        className={
          !left
            ? ' w-full py-[60px] max-w-[1720px] m-auto'
            : 'w-full py-[60px] max-w-[1720px] m-auto'
        }
      >
        <div
          className={
            left
              ? 'flex flex-col gap-y-10 lg:flex-row w-10/12 m-auto justify-between items-center gap-x-10'
              : 'flex flex-col gap-y-10 lg:flex-row-reverse w-10/12 m-auto justify-between items-center'
          }
        >
          <div className="w-full lg:w-[40%] flex flex-col justify-center items-center  gap-5 relative">
            <div className="w-full flex flex-grow-0 order-0 flex-none justify-center items-center lg:justify-start lg:items-start">
              <img
                className="rounded-[9px]  shadow bg-contain bg-center bg-no-repeat lg:w-[467px] h-[200px] w-[300px] lg:h-[422px] object-cover transition duration-300 ease-out"
                src={img[position]}
                alt="Imagen seccions"
              />
            </div>
            {handleMove && (
              <div className="flex gap-5 w-full justify-center items-center lg:justify-start">
                {img.length > 1 ? (
                  <>
                    <button
                      className="arrow-left"
                      onClick={() => handleMove(true)}
                    ></button>
                    <button
                      className="arrow-right"
                      onClick={() => handleMove(false)}
                    ></button>
                  </>
                ) : (
                  ''
                )}
              </div>
            )}
          </div>
          <div className="flex flex-col gap-8 max-w-[600px] text-center lg:text-left">
            <h2 className="text-[23px] md:text-3xl tracking-[0.15em] font-town font-bold">
              {title[language]}
            </h2>
            <p className="lg:ml-[40px] font-segoe text-[14px] tracking-[0.03em] leading-[27px] md:text-[16px] md:tracking-[0.04em] font-thin md:leading-[29px] w-[90%] m-auto">
              {description[language]}
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HeroSection
