import { FC, useContext, useEffect } from 'react'
import GeneralContext from '../general/generalContext/GeneralContext'
import { textPolitics } from './data'

const Politics: FC = () => {
  const { handleHeaderColor } = useContext(GeneralContext)

  useEffect(() => {
      handleHeaderColor('black')
    return () => {
      handleHeaderColor('white')
    }
  })

  return (
    <section className="mt-[240px] w-10/12 m-auto container">
      <h1 className="font-town text-[23px] md:text-[30px] font-bold tracking-[0.15em] leading-[29px] pb-8">
        {textPolitics.headline.es}
      </h1>
      <p className="font-segoe text-[14px] md:text-[16px] leading-[29px] tracking-[0.04em] font-extralight">
        {textPolitics.paragraphTwo.es}
      </p>
      <br />
      <p className="font-segoe text-[14px] md:text-[16px] leading-[29px] tracking-[0.04em] font-extralight">
        {textPolitics.paragraphThree.es}
      </p>
      <br />
      <p className="font-segoe text-[14px] md:text-[16px] leading-[29px] tracking-[0.04em] font-extralight">
        {textPolitics.paragraphFour.es}
      </p>
      <br />
      <p className="font-segoe text-[14px] md:text-[16px] leading-[29px] tracking-[0.04em] font-extralight">
        {textPolitics.paragraphFive.es}
      </p>
      <br />
      <p className="font-segoe text-[14px] md:text-[16px] leading-[29px] tracking-[0.04em] font-extralight">
        {textPolitics.paragraphSix.es}
      </p>
      <br />
      <p className="font-segoe text-[14px] md:text-[16px] leading-[29px] tracking-[0.04em] font-extralight">
        {textPolitics.paragraphSeven.es}
      </p>
      <br />
    </section>
  )
}

export default Politics
