import ToggleLanguage from './ToggleLanguage'
import Logo from '../../../assets/logo.svg'
import Hamburger from '../../../assets/hamburger.svg'
import { useContext } from 'react'
import GeneralContext from '../generalContext/GeneralContext'
import { Link, useHistory, useLocation, withRouter } from 'react-router-dom'
import { headerData } from './data'

const Header = () => {
  const { toggleMenu, headerColor, language } = useContext(GeneralContext)

  const history = useHistory()

  const location = useLocation()

  return (
    <div className="absolute top-0 left-[6%] right-[6%] pt-[50px] max-w-[1500px] mx-auto">
      <div className="flex justify-between w-full">
        <img
          src={Hamburger}
          alt="Logo"
          onClick={() => toggleMenu(true)}
          className="cursor-pointer w-[40px] sm:w-[50px] select-none"
          style={headerColor === 'black' ? { filter: 'invert(100%)' } : {}}
        />
        <img
          src={Logo}
          alt="Logo"
          onClick={() => history.push('/')}
          className="cursor-pointer w-[95px] sm:w-[128px] select-none"
          style={headerColor === 'black' ? { filter: 'invert(100%)' } : {}}
        />
        <ToggleLanguage />
      </div>
      <div className="w-[600px] mx-auto hidden lg:flex justify-between pt-[30px] font-segoe text-sm">
        <div
          className={
            location.pathname === '/'
              ? headerColor === 'black'
                ? `tab-selected-black`
                : `tab-selected-white`
              : headerColor === 'black'
              ? `tab-black`
              : `tab-white`
          }
          onClick={() => history.push('/')}
        >
          <span>{headerData.home[language]}</span>
        </div>
        <Link
          className={
            location.pathname.includes('que-hacemos')
              ? headerColor === 'black'
                ? `tab-selected-black`
                : `tab-selected-white`
              : headerColor === 'black'
              ? `tab-black`
              : `tab-white`
          }
          to="/que-hacemos"
        >
          <span>{headerData.whatWeDo[language]}</span>
        </Link>
        <Link
          className={
            location.pathname.includes('nuestra-empresa')
              ? headerColor === 'black'
                ? `tab-selected-black`
                : `tab-selected-white`
              : headerColor === 'black'
              ? `tab-black`
              : `tab-white`
          }
          to="/nuestra-empresa"
        >
          <span>{headerData.ourMotor[language]}</span>
        </Link>
        <Link
          className={
            location.pathname.includes('publicaciones')
              ? headerColor === 'black'
                ? `tab-selected-black`
                : `tab-selected-white`
              : headerColor === 'black'
              ? `tab-black`
              : `tab-white`
          }
          to="/publicaciones"
        >
          <span>{headerData.posts[language]}</span>
        </Link>
      </div>
    </div>
  )
}

export default withRouter(Header)
