const IndustryCard = ({ title, img }: { title: string; img: string }) => {
  return (
    <div className="w-full mt-6 sm:mt-8 lg:mt-10">
      <div className="bg-black text-white font-segoe font-semibold text-[9px] sm:text-xs leading-3 tracking-[0.15em] px-4 py-2 rounded-t-lg w-max max-w-full">
        {title}
      </div>
      <div className="w-full relative">
        <img
          src={img}
          alt={title}
          className="w-full rounded-lg rounded-tl-none"
          style={{
            aspectRatio: '5/4'
          }}
          loading="lazy"
        />
      </div>
    </div>
  )
}

export default IndustryCard
