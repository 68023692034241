import InformativeModal from '../informativeModal/InformativeModal'

const WorkWithUs = ({ handleClose }: { handleClose: () => void }) => {
  return (
    <InformativeModal handleClose={handleClose} type="work">
      <h2 className="font-bold text-xl tracking-[0.15em] w-[260px] lg:w-max">
        TRABAJA CON NOSOTROS
      </h2>
      <h3 className="text-sm tracking-[0.06em] mt-3">
        EL TALENTO QUE BUSCAMOS
      </h3>
      <p className="mt-4 text-base tracking-[0.04em] leading-7 mb-10 sm:mb-36 lg:mb-9">
        Las personas son el motor de LKS nos caracterizamos por ser personas
        íntegras, apasionadas, responsables, creativas, innovadoras y con
        superación profesional constante.
        <br />
        <br /> Haz parte de esta familia que trabaja día a día para brindar un
        servicio de calidad.
        <br />
        <br /> En LKS fomentamos el aprendizaje continuo con el fin de lograr
        nuestra misión y visión. <br /> Si estás interesado en trabajar con
        nosotros y quieres que tu hoja de vida sea tenida en cuenta, envía un
        correo a: <br /> <br />
        <span className="font-bold text-primary">hseq@lks.com.co</span> <br />
        <span className="font-bold">En el asunto del correo escribe:</span> Hoja
        de vida, tu nombre completo, tu profesión y ciudad.
      </p>
    </InformativeModal>
  )
}

export default WorkWithUs
