import { useContext, useEffect, useRef, useState } from 'react'
import InformativeModal from '../../general/informativeModal/InformativeModal'
import InformativeModalResponsive from '../../general/informativeModal/InformativeModalResponsive'
import GeneralContext from '../../general/generalContext/GeneralContext'
import { buttonText } from '../data'

const InfoService = ({
  activeService,
  handleClose
}: {
  handleClose: () => void
  activeService: any
}) => {
  const { toggleContact, language } = useContext(GeneralContext)
  const [position, setPosition] = useState(0)
  const timerRef = useRef<any>(null)

  const length = activeService.images.length

  const nextSlide = () => {
    setPosition(position === length - 1 ? 0 : position + 1)
  }

  const prevSlide = () => {
    setPosition(position === 0 ? length - 1 : position - 1)
  }

  if (
    !Array.isArray(activeService.images) ||
    activeService.images.length <= 0
  ) {
    return null
  }

  useEffect(() => {
    timerRef.current = setInterval(() => {
      setPosition((prevState) => (prevState + 1) % length)
    }, 2000)
    return () => {
      clearInterval(timerRef.current)
    }
  }, [position])

  return (
    <>
      {activeService.text ? (
        <InformativeModal handleClose={handleClose} type="service">
          <div className="py-10 flex flex-col space-y-8">
            <div className="flex w-[70%] gap-x-10">
              <h2 className="w-[50%] font-town text-[14px tracking-[0.15em] font-bold">
                {activeService.headline[language]}
              </h2>
              <p className="font-town text-[14px] w-full ">
                {activeService.description[language]}
              </p>
            </div>
            <div className="flex flex-col lg:flex-row justify-center items-center lg:justify-between lg:items-start w-full">
              <div className="flex flex-col w-full justify-center items-center lg:justify-start lg:items-start gap-8 ">
                <img
                  src={activeService.images[position]}
                  className="rounded w-full lg:max-w-[304px] h-[304px] object-cover"
                  alt="imagen servicio"
                />
                {activeService.images.length > 1 ? (
                  <div className="flex gap-5 w-full justify-center items-center lg:justify-start">
                    <button className="arrow-left" onClick={prevSlide}></button>
                    <button
                      className="arrow-right"
                      onClick={nextSlide}
                    ></button>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div className="w-full flex flex-col justify-center items-center text-center mt-6 lg:mt-0 lg:text-left space-y-14 ">
                <p className="w-[100%] font-segoe font-[12px] leading-[29px] tracking-[0.04em] font-thin">
                  {activeService.text[language]}
                </p>
                <button
                  type="button"
                  className="btn-primary  text-black border-black hover:bg-black hover:text-white h-full"
                  onClick={() => toggleContact(true)}
                >
                  {buttonText[language]}
                </button>
              </div>
            </div>
          </div>
        </InformativeModal>
      ) : (
        <InformativeModalResponsive handleClose={handleClose} type="service">
          <div className="w-full py-10 flex flex-col space-y-6">
            <div className="flex w-[100%] gap-x-10 items-center flex-wrap gap-5">
              <h2 className=" w-full font-town text-[14px]  tracking-[0.15em] font-bold">
                {activeService.headline[language]}
              </h2>
              <p className="w-full font-town text-[14px]  ">
                {activeService.description[language]}
              </p>
            </div>
            <div className="flex flex-col md:flex-col gap-8 justify-around w-full">
              <div className="flex flex-col justify-center items-center w-full gap-5 ">
                <img
                  src={activeService.images[position]}
                  className="rounded w-[100%] h-[330px] object-cover"
                  alt=""
                />
                {activeService.images.length > 1 ? (
                  <div className="flex gap-5 w-full justify-center items-center lg:justify-center">
                    <button className="arrow-left" onClick={prevSlide}></button>
                    <button
                      className="arrow-right"
                      onClick={nextSlide}
                    ></button>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div className="w-full m-auto flex flex-col justify-center items-center">
                <button
                  type="button"
                  className="btn-primary text-black border-black hover:bg-black hover:text-white"
                  onClick={() => toggleContact(true)}
                >
                  {buttonText[language]}
                </button>
              </div>
            </div>
          </div>
        </InformativeModalResponsive>
      )}
    </>
  )
}

export default InfoService
