import React, { useContext, useEffect } from 'react'
import GeneralContext from '../general/generalContext/GeneralContext'
import { textGuarantee } from './data'

const Guarantee = () => {
  
  const { handleHeaderColor } = useContext(GeneralContext)

  useEffect(() => {
    handleHeaderColor('black')
  return () => {
    handleHeaderColor('white')
  }
}, [])

  return (
    <section className="mt-[240px] w-10/12 m-auto container">
      <h1 className="font-town text-[23px] md:text-[30px] font-bold tracking-[0.15em] leading-[29px] pb-8">
        {textGuarantee.headline.es}
      </h1>
      <p className="font-segoe text-[14px] md:text-[16px] leading-[29px] tracking-[0.04em] font-extralight">
        {textGuarantee.paragraphOne.es}
      </p>
      <br />
      <p className="font-segoe text-[14px] md:text-[16px] leading-[29px] tracking-[0.04em] font-extralight">
        {textGuarantee.paragraphTwo.es}
      </p>
      <br />
      <p className="font-segoe text-[14px] md:text-[16px] leading-[29px] tracking-[0.04em] font-extralight">
        {textGuarantee.paragraphThree.es}
      </p>
      <br />
      <p className="font-segoe text-[14px] md:text-[16px] leading-[29px] tracking-[0.04em] font-extralight">
        {textGuarantee.paragraphFour.es}
      </p>
      <br />
      <p className="font-segoe text-[14px] md:text-[16px] leading-[29px] tracking-[0.04em] font-extralight">
        {textGuarantee.paragraphFive.es}
      </p>
      <br />
      <p className="font-segoe text-[14px] md:text-[16px] leading-[29px] tracking-[0.04em] font-extralight">
        {textGuarantee.paragraphSix.es}
      </p>
      <br />
      <p className="font-segoe text-[14px] md:text-[16px] leading-[29px] tracking-[0.04em] font-extralight">
        {textGuarantee.paragraphSeven.es}
      </p>
      <br />
      <p className="font-segoe text-[14px] md:text-[16px] leading-[29px] tracking-[0.04em] font-extralight">
        {textGuarantee.paragraphEight.es}
      </p>
      <br />
    </section>
  )
}

export default Guarantee
