import PlantServices from '../../../assets/images/homepage/servicesPlant.jpg'
import SiteServices from '../../../assets/images/homepage/servicesSite.jpg'
import Diagnoses from '../../../assets/images/homepage/diagnoses.jpg'

type Service = {
  img: string
  title: { es: string; en: string } | string | any
  text: { es: string; en: string } | string | any
}

type buttonText = {
  es: string | any
  en: string | any
}

export const textButtonSection: buttonText | any = {
  es: 'CONOCE MÁS',
  en: 'KNOW MORE'
}

export const services: Service[] = [
  {
    img: Diagnoses,
    title: {
      es: 'Diagnósticos',
      en: 'Diagnostics'
    },
    text: {
      es: 'Mantenimiento predictivo. Utilizamos alta tecnología para la realización de diagnósticos que evidencien el estado actual y preciso de sus equipos.',
      en: 'Predictive maintenance. We use high technology to perform diagnostics that show the current and precise state of your equipment.'
    }
  },
  {
    img: SiteServices,
    title: {
      es: 'Servicios EN SITIO',
      en: 'Site Services'
    },
    text: {
      es: 'Atención de urgencias 24 horas 7 días a la semana. Cobertura y asesoría técnica especializada nacional e internacionalmente. Proyectos de servicio integral de mantenimiento.',
      en: '24-hour emergency service. Coverage and specialized technical assistance from national and international partners. Service-integrated maintenance projects.'
    }
  },
  {
    img: PlantServices,
    title: {
      es: 'Servicios EN planta',
      en: 'Plant Services'
    },
    text: {
      es: 'Conoce las actividades de mantenimiento preventivo y correctivo de maquinaria eléctrica rotativa que ejecutamos en nuestras instalaciones. ',
      en: 'Learn about the preventive and corrective maintenance of rotary electrical machinery that we execute in our facilities.'
    }
  }
]
