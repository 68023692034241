export const headerData: any = {
  home: {
    es: 'INICIO',
    en: 'HOME'
  },
  whatWeDo: {
    es: 'QUÉ HACEMOS',
    en: 'WHAT WE DO'
  },
  ourMotor: {
    es: 'NUESTRO MOTOR',
    en: 'OUR MOTOR'
  },
  posts: {
    es: 'PUBLICACIONES',
    en: 'POSTS'
  }
}
