import React from 'react'
import IconCross from '../../../assets/cross.svg'

const InformativeModal = ({
  children,
  handleClose,
  type
}: {
  children: React.ReactNode
  handleClose: () => void
  type: string
}) => {
  return (
    <div className="fixed top-0 left-0 bottom-0 right-0 flex flex-col items-center justify-center z-10">
      <div
        className="absolute top-0 left-0 bottom-0 right-0 bg-black bg-opacity-40"
        onClick={() => handleClose()}
      />
      <div
        className={
          type === 'directory'
            ? 'lg:max-w-[1170px] container-informative-modal'
            : 'lg:max-w-[897px] container-informative-modal'
        }
      >
        <div
          className="rounded-bl-[9px] bg-[#C4C4C4] w-[66px] h-[61px] min-h-[61px] self-end flex items-center justify-center cursor-pointer z-20"
          onClick={() => handleClose()}
        >
          <img src={IconCross} alt="Close" />
        </div>
        <div className="mt-[-21px] px-[30px] sm:px-[40px] lg:px-[50px] z-10">
          {children}
        </div>
      </div>
    </div>
  )
}

export default InformativeModal
