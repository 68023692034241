import { useContext } from 'react'
import GeneralContext from '../generalContext/GeneralContext'
import IconColombia from '../../../assets/colombia.svg'
import IconUK from '../../../assets/uk.svg'

const ToggleLanguage = () => {
  const { toggleLanguage, language } = useContext(GeneralContext)

  return (
    <div
      className="bg-[#EEE] w-[53px] h-[28px] sm:w-[68px] sm:h-[36px] rounded-[18px] flex items-center justify-between relative cursor-pointer select-none"
      onClick={() => toggleLanguage()}
    >
      <img
        src={language === 'es' ? IconColombia : IconUK}
        alt="Lang"
        className="absolute transition-all z-10 w-[23px] sm:w-[30px]"
        style={language === 'es' ? { left: '5%' } : { left: '50%' }}
      />
      <span
        className="text-[11px] sm:text-[12px] text-[#666] uppercase absolute transition-all"
        style={language === 'es' ? { right: '20%' } : { right: '60%' }}
      >
        {language}
      </span>
    </div>
  )
}

export default ToggleLanguage
