import React, { useEffect, useState } from 'react'
import MenuService from './components/MenuService'
import { servicesData } from './data'
import ServiceCards from './components/ServiceCards'
import { useLocation } from 'react-router'

const Services = () => {
  const query = new URLSearchParams(useLocation().search)
  const idCategory = query.get('idCategoria')
  const [activeCategory, setActiveCategory] = useState(servicesData[0])

  useEffect(() => {
    const selectedCategory = servicesData.find(
      (category: any) => String(category.id) === idCategory
    )

    setActiveCategory(selectedCategory || servicesData[0])
  }, [idCategory])

  return (
    <div>
      <MenuService
        setActiveCategory={setActiveCategory}
        categories={servicesData}
        activeCategory={activeCategory}
      />
      <div className="w-11/12 md:w-[60%] m-auto font-segoe mt-[60px] text-center font-thin">
        <h3 className="text-[16px] font-normal letter-spacing-[0.04em] leading-[29px]">
          {/* {activeCategory && activeCategory.paragraph[language]} */}
        </h3>
      </div>
      <ServiceCards activeCategory={activeCategory} />
    </div>
  )
}

export default Services
