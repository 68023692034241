import React, { useContext, useEffect, useState } from 'react'
import IconCross from '../../../assets/cross.svg'
import GeneralContext from '../generalContext/GeneralContext'
import Whatsapp from '../../../assets/whatsappBlack.svg'
import Directory from './Directory'
import { solicitudContacto } from '../../../actions/ContactActions'
import IContact from './IContact'
import LoadingIndicator from '../loadingIndicator/LoadingIndicator'
import { getDownloadUrl } from '../../../proxy/FirebaseStorage'
import types from './MotorTypes'
import { contactData, formData } from './data'

const Contact = ({ open }: { open: boolean }) => {
  const { toggleContact, language } = useContext(GeneralContext)

  const [data, setData] = useState<IContact>({
    name: '',
    email: '',
    number: '',
    description: '',
    tag: '',
    type: '',
    otherType: '',
    power: '',
    voltage: '',
    company: '',
    city: '',
    option: 'contact'
  })
  const [dialogue, setDialogue] = useState<boolean>(false)
  const [sent, setSent] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [brochure, setBrochure] = useState<any>('')

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    e.preventDefault()
    setData({
      ...data,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)
    let res = ''
    if (data.option === 'contact') {
      if (data.name && data.email && data.number && data.description) {
        res = await solicitudContacto(data)
      }
    } else if (data.option === 'quote') {
      if (
        data.name &&
        data.email &&
        data.tag &&
        data.type &&
        (data.type !== 'Otro' || data.otherType) &&
        data.power &&
        data.voltage &&
        data.description
      ) {
        let newData: IContact = data
        if (data.type === 'Otro') {
          newData = { ...data, type: data.otherType }
        }
        res = await solicitudContacto(newData)
      }
    }
    console.log(res)
    setLoading(false)
    setSent(true)
  }

  useEffect(() => {
    const getBrochure = async () => {
      const url = await getDownloadUrl(
        'gs://lks-landing.appspot.com/brochure.pdf'
      )
      setBrochure(url)
    }
    getBrochure()
  }, [])

  return (
    <div
      className="fixed top-0 right-0 h-full max-h-full w-full bg-white flex flex-col lg:flex-row z-50 transition-all overflow-x-hidden scrollbar scrollbar-thumb"
      style={open ? { right: '0' } : { right: '-100%' }}
    >
      {dialogue && <Directory handleClose={() => setDialogue(false)} />}
      {loading && <LoadingIndicator />}
      <div
        className="absolute top-0 right-0 sm:left-0 sm:right-auto rounded-bl-[9px] sm:rounded-bl-none sm:rounded-br-[9px] bg-[#C4C4C4] w-[66px] h-[61px] flex items-center justify-center cursor-pointer invert-img"
        onClick={() => toggleContact(false)}
      >
        <img src={IconCross} alt="Close" />
      </div>
      <div className="bg-[#F8F8F8] lg:w-[55%] flex justify-end">
        <div className="flex flex-col px-[30px] sm:px-[86px] w-full box-border pt-14 sm:pt-[86px] pb-14 lg:pt-[123px] max-w-[900px] overflow-y-auto scrollbar-none">
          <div className="flex flex-col sm:flex-row sm:justify-between">
            <div className="sm:mr-5 font-segoe">
              <h2 className="text-xl tracking-[0.15em] font-bold">
                {contactData.bogotaTitle[language]}:
              </h2>
              <p className="text-sm tracking-[0.01em] leading-7 mt-3">
                {contactData.addressBogota}
                <br />
                {contactData.pbxBogota}
                <br />
                {contactData.phoneBogota[language]}
              </p>
              <a
                className="flex mt-6 items-center"
                href="https://api.whatsapp.com/send?phone=+573017879332"
              >
                <img src={Whatsapp} alt="Whatsapp" className="mr-4" />
                <span className="text-sm tracking-[0.15em]">
                  {contactData.ctaWhatsapp[language]}
                </span>
              </a>
              <iframe
                loading="lazy"
                allowFullScreen
                className="w-full h-[183px] mt-5 object-cover"
                src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBJcB-r_T9j9JgpkrXSD8SwfW5Bsw2Itwk&q=Calle%2024%20%23%2024%20%E2%80%93%2017%2C%20Bogota"
              />
            </div>
            <div className="mt-8 sm:mt-0 font-segoe">
              <h2 className="text-xl tracking-[0.15em] font-bold">
                {contactData.cartagenaTitle}:
              </h2>
              <p className="text-sm tracking-[0.01em] leading-7 mt-3">
                {contactData.addressCartagena}
                <br />
                {contactData.pbxCartagena}
                <br />
                {contactData.phoneCartagena[language]}
              </p>
              <a
                className="flex mt-6 items-center"
                href="https://api.whatsapp.com/send?phone=+573178944281"
              >
                <img src={Whatsapp} alt="Whatsapp" className="mr-4" />
                <span className="text-sm tracking-[0.15em]  font-boldfont-segoe">
                  {contactData.ctaWhatsapp[language]}
                </span>
              </a>
              <iframe
                loading="lazy"
                allowFullScreen
                className="w-full h-[183px] mt-5 object-cover"
                src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBJcB-r_T9j9JgpkrXSD8SwfW5Bsw2Itwk&q=Diag%2021%20A%20%23%2055%20%E2%80%93%2064%20Barrio%20el%20Bosque%2C%20Cartagena"
              />
            </div>
          </div>
          <div className="flex flex-col sm:flex-row items-start mt-12 sm:mt-16 w-full">
            <a
              className="btn-primary sm:mr-5"
              href={brochure}
              download="brochure.pdf"
              target="_blank"
              rel="noreferrer"
            >
              {' '}
              {contactData.downloadBrochure[language]}
            </a>
            <span
              className="btn-primary mt-3 sm:mt-0"
              onClick={() => setDialogue(true)}
            >
              {contactData.mail[language]}
            </span>
          </div>
        </div>
      </div>
      <div className="w-full lg:w-[45%] flex flex-col items-center pt-20 sm:pt-14 lg:pt-24 pb-16 px-[30px] lg:max-w-[675px] lg:overflow-y-auto scrollbar-none">
        {sent ? (
          <div className="my-auto mx-auto flex flex-col items-center">
            <svg
              width="79"
              height="85"
              viewBox="0 0 79 85"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 8.75V61.25C0 66.0756 3.92437 70 8.75 70H24.4388L39.375 84.9362L54.3112 70H70C74.8256 70 78.75 66.0756 78.75 61.25V8.75C78.75 3.92437 74.8256 0 70 0H8.75C3.92437 0 0 3.92437 0 8.75ZM8.75 8.75H70V61.25H50.6888L39.375 72.5638L28.0612 61.25H8.75V8.75Z"
                fill="black"
              />
              <path
                d="M62.1555 22.7807L55.9692 16.5945L34.9999 37.5639L24.968 27.532L18.7817 33.7182L34.9999 49.9364L62.1555 22.7807Z"
                fill="black"
              />
            </svg>
            <h2 className="font-town font-black text-[46px] tracking-[0.15em] mt-2">
              {formData.thanks[language]}
            </h2>
            <p className="font-segoe text-sm leading-4 tracking-[0.15em] text-center w-[237px] mt-8">
              {formData.userMessage[language]}
            </p>
          </div>
        ) : (
          <div className="flex flex-col items-center max-w-[356px] sm:w-[356px] w-full">
            <div className="self-start flex">
              <span
                className={
                  data.option === 'contact'
                    ? 'tab-selected-primary mr-6 text-base sm:text-sm'
                    : 'tab-primary mr-6 text-base sm:text-sm'
                }
                onClick={() => setData({ ...data, option: 'contact' })}
              >
                {formData.contactText[language]}
              </span>
              <span
                className={
                  data.option === 'quote'
                    ? 'tab-selected-primary text-base sm:text-sm flex'
                    : 'tab-primary text-base sm:text-sm flex'
                }
                onClick={() => setData({ ...data, option: 'quote' })}
              >
                {formData.quote[language]}
                <div className="hidden sm:block">
                  &nbsp;{formData.quoteWithUs[language]}
                </div>
              </span>
            </div>
            <form
              className="flex flex-col items-start w-full mt-5"
              onSubmit={handleSubmit}
            >
              <label className="text-sm tracking-[0.15em]">
                {formData.formName[language]}
              </label>
              <input
                type="text"
                value={data?.name}
                name="name"
                onChange={handleChange}
                className="input-form mt-2 w-full"
                required
              />
              <label className="text-sm tracking-[0.15em] mt-3">
                {' '}
                {formData.formEmail[language]}{' '}
              </label>
              <input
                type="email"
                value={data?.email}
                name="email"
                onChange={handleChange}
                className="input-form mt-2 w-full"
                required
              />
              <label className="text-sm tracking-[0.15em] mt-3">
                {' '}
                {formData.formCity[language]}{' '}
              </label>
              <input
                type="text"
                value={data?.city}
                name="city"
                onChange={handleChange}
                className="input-form mt-2 w-full"
                required
              />
              <label className="text-sm tracking-[0.15em] mt-3">
                {formData.formCompany[language]}
              </label>
              <input
                type="text"
                value={data?.company}
                name="company"
                onChange={handleChange}
                className="input-form mt-2 w-full"
                required
              />
              {data.option === 'quote' ? (
                <>
                  <label className="text-sm tracking-[0.15em] mt-3">
                    {formData.formTag}
                  </label>
                  <input
                    type="text"
                    value={data?.tag}
                    name="tag"
                    onChange={handleChange}
                    className="input-form mt-2 w-full"
                    required
                  />
                  <label className="text-sm tracking-[0.15em] mt-3">
                    {formData.formTeam[language]}
                  </label>
                  <select
                    name="type"
                    value={data?.type}
                    className="input-form mt-2 py-[10px] px-1 w-full scrollbar scrollbar-thumb bg-white"
                    onChange={(e) => setData({ ...data, type: e.target.value })}
                    defaultValue=""
                    required
                  >
                    <option value={''} key={''} className="hidden" disabled />
                    {types.map((type) => (
                      <option
                        value={type}
                        key={type}
                        className="text-sm font-segoe tracking-[0.15em] my-10 text-[#666] font-light "
                      >
                        {type}
                      </option>
                    ))}
                  </select>
                  {data?.type === 'Otro' && (
                    <input
                      type="text"
                      value={data?.otherType}
                      name="otherType"
                      onChange={handleChange}
                      className="input-form mt-2 w-full"
                      required
                    />
                  )}
                  <div className="flex justify-between mt-3">
                    <div className="w-[48%]">
                      <label className="text-sm tracking-[0.15em] mt-3">
                        {formData.formPower[language]}
                      </label>
                      <input
                        type="text"
                        value={data?.power}
                        name="power"
                        onChange={handleChange}
                        className="input-form mt-2 w-full"
                        required
                      />
                    </div>
                    <div className="w-[48%]">
                      <label className="text-sm tracking-[0.15em] mt-3">
                        {formData.formVoltage[language]}
                      </label>
                      <input
                        type="text"
                        value={data?.voltage}
                        name="voltage"
                        onChange={handleChange}
                        className="input-form mt-2 w-full"
                        required
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <label className="text-sm tracking-[0.15em] mt-3">
                    {formData.formNumber[language]}
                  </label>
                  <input
                    type="number"
                    value={data?.number}
                    name="number"
                    onChange={handleChange}
                    className="input-form mt-2 w-full"
                    required
                  />
                </>
              )}
              <label className="text-sm tracking-[0.15em] mt-3">
                {formData.formDescription[language]}
              </label>
              <textarea
                value={data?.description}
                name="description"
                onChange={handleChange}
                className="input-form mt-2 w-full resize-none"
                required
              />
              <button type="submit" className="btn-primary mt-3">
                {formData.formButton[language]}
              </button>
            </form>
          </div>
        )}
      </div>
    </div>
  )
}

export default Contact
