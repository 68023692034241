import Banner from '../general/banner/Banner'
import OurCompanyBanner from './../../assets/images/ourCompany/ourCompany.jpg'
import Family from './../../assets/images/ourCompany/familyLKS.png'
import AggregateValue from './AggregateValue'
import History from './History'
import Industries from './Industries'
import { ourCompanyData } from './data'
import GeneralContext from '../general/generalContext/GeneralContext'
import { useContext } from 'react'

const OurCompany = () => {
  const { language } = useContext(GeneralContext)
  return (
    <div>
      <Banner
        title={ourCompanyData.title[language]}
        bgImage={OurCompanyBanner}
      />
      <div className="px-[30px] my-[60px] w-full sm:px-[100px] max-w-[1500px] mx-auto flex flex-col-reverse lg:flex-row items-center">
        <p className="font-segoe font-semiLight leading-7 tracking-[0.04em] mt-5 lg:pr-[5%] pt-6 lg:pt-0">
          {ourCompanyData.description[language]}
          <br />
          <br />
          <br />
          {ourCompanyData.descriptionTwo[language]}
        </p>
        <img
          src={Family}
          alt="Nuestra empresa"
          className="w-[350px] max-w-full object-cover rounded-lg"
          style={{
            aspectRatio: '8/7'
          }}
        />
      </div>
      <AggregateValue language={language} />
      <History language={language} />
      <Industries language={language} />
    </div>
  )
}

export default OurCompany
