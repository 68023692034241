import { useContext, useEffect, useState } from 'react'
import LogoBlack from '../../../assets/logoBlack.svg'
import Twitter from '../../../assets/twitterBlack.svg'
import Facebook from '../../../assets/facebookBlack.svg'
import Instagram from '../../../assets/instagramBlack.svg'
import Linkedin from '../../../assets/linkedinBlack.svg'
import Texture from '../../../assets/textureFooter.png'
import WorkWithUs from './WorkWithUs'
import { useHistory } from 'react-router-dom'
import { getDownloadUrl } from '../../../proxy/FirebaseStorage'
import { footerText } from './data'
import GeneralContext from '../generalContext/GeneralContext'

const Footer = () => {
  const { language } = useContext(GeneralContext)
  const [dialogue, setDialogue] = useState<boolean>(false)
  const history = useHistory()

  const [brochure, setBrochure] = useState<any>('')

  useEffect(() => {
    const getBrochure = async () => {
      const url = await getDownloadUrl(
        'gs://lks-landing.appspot.com/brochure.pdf'
      )
      setBrochure(url)
    }
    getBrochure()
  }, [])

  return (
    <div className="flex flex-col items-center lg:items-start w-full lg:mx-auto pt-16 font-segoe">
      <div className="max-w-[1500px] mx-auto flex flex-col items-center lg:items-start">
        {dialogue && <WorkWithUs handleClose={() => setDialogue(false)} />}
        <img src={LogoBlack} alt="Logo negro" className="lg:ml-[5%]" />
        <div
          style={{ gridTemplateColumns: '1fr 1fr 4fr max-content' }}
          className="flex flex-col lg:grid items-center lg:gap-x-8 lg:items-start w-full lg:w-[90%] mx-auto mt-[25px] lg:pb-[60px]  "
        >
          <div className="hidden lg:flex flex-col justify-between h-[200px] tracking-[0.04em]">
            <h4 className="font-bold tracking-[0.15em]">
              {footerText.whyUs[language]}
            </h4>
            <span
              className="cursor-pointer hover:underline"
              onClick={() => history.push('/calidad')}
            >
              {footerText.quality[language]}
            </span>
            <span
              className="cursor-pointer hover:underline"
              onClick={() => history.push('/seguridad')}
            >
              {footerText.security[language]}
            </span>
            <span
              className="cursor-pointer hover:underline"
              onClick={() => history.push('/prestigio')}
            >
              {footerText.prestige[language]}
            </span>
            <span
              className="cursor-pointer hover:underline"
              onClick={() => history.push('/innovacion')}
            >
              {footerText.innovation[language]}
            </span>
          </div>
          <div className="hidden lg:flex flex-col justify-between h-[250px] mr-[10px] tracking-[0.04em]">
            <h4 className="font-bold tracking-[0.15em]">
              {footerText.ourServices[language]}
            </h4>
            <span
              className="leading-5 hover:underline cursor-pointer"
              onClick={() => history.push('/que-hacemos?idCategoria=1')}
            >
              {footerText.workshop[language]}
            </span>
            <span
              className="leading-5 hover:underline cursor-pointer"
              onClick={() => history.push('/que-hacemos?idCategoria=2')}
            >
              {footerText.serviceOnSite[language]}
            </span>
            <span
              className="leading-5 hover:underline cursor-pointer"
              onClick={() => history.push('/que-hacemos?idCategoria=3')}
            >
              {footerText.technicalSupport[language]}
            </span>
            <span
              className="leading-5 hover:underline cursor-pointer"
              onClick={() => history.push('/que-hacemos?idCategoria=4')}
            >
              {footerText.proyects[language]}
            </span>
            <span
              className="leading-5 hover:underline cursor-pointer"
              onClick={() => history.push('/que-hacemos?idCategoria=5')}
            >
              {footerText.brands[language]}
            </span>
          </div>
          <div className="flex flex-col sm:flex-row lg:mr-[10px] lg:justify-center">
            <div className="flex flex-col items-center sm:items-start text-[15px] mt-10 lg:mt-0 tracking-[0.04em] leading-[29px] text-center lg:text-left sm:mr-6 lg:mr-10">
              <h4 className="font-bold tracking-[0.15em]">
                {footerText.nameBogota[language]}
              </h4>
              <span>{footerText.bogotaCity[language]}</span>
              <span>{footerText.neighborhoodBogota[language]}</span>
              <span>{footerText.pbxBogota}</span>
              <a
                className="hover:underline"
                href="https://api.whatsapp.com/send?phone=+573017879332"
                target="_blank"
                rel="noreferrer"
              >
                {footerText.cellphoneBogota[language]}
              </a>
            </div>
            <div className="flex flex-col items-center sm:items-start text-[15px] mt-10 lg:mt-0 tracking-[0.04em] leading-[29px] text-center lg:text-left">
              <h4 className="font-bold tracking-[0.15em]">
                {footerText.nameCartagena}
              </h4>
              <span>{footerText.cartagenaCity[language]}</span>
              <span>{footerText.neighborhoodCartagena[language]}</span>
              <span>{footerText.pbx}</span>
              <a
                className="hover:underline"
                href="https://api.whatsapp.com/send?phone=+573178944281"
                target="_blank"
                rel="noreferrer"
              >
                {footerText.cellphoneCartagena[language]}
              </a>
            </div>
          </div>
          <div className="flex flex-col lg:items-start">
            <div className="flex flex-col items-center lg:items-start">
              <a
                href="mailto:info@lks.com.co"
                className="mt-10 lg:mt-0 text-[15px] tracking-[0.15em] hover:underline"
              >
                info@lks.com.co
              </a>
              <div className="flex w-[185px] lg:w-[170px] mt-[20px] justify-between">
                <a href="https://www.linkedin.com/in/lks-sas-industrial-de-servicios-7587b2209">
                  <img
                    src={Linkedin}
                    alt="Linkedin"
                    className="cursor-pointer hover:scale-125 duration-100"
                  />
                </a>
                <a href="https://www.facebook.com/IndustrialLKSsas">
                  <img
                    src={Facebook}
                    alt="Facebook"
                    className="cursor-pointer hover:scale-125 duration-100"
                  />
                </a>
                <a href="https://www.instagram.com/lks.sas/">
                  <img
                    src={Instagram}
                    alt="Instagram"
                    className="cursor-pointer hover:scale-125 duration-100"
                  />
                </a>
                <a href="https://twitter.com/lks_sas">
                  <img
                    src={Twitter}
                    alt="Twitter"
                    className="cursor-pointer hover:scale-125 duration-100"
                  />
                </a>
              </div>
            </div>
            <div className="flex flex-col items-center lg:items-start mt-12">
              <span className="btn-primary" onClick={() => setDialogue(true)}>
                {footerText.workWithUs[language].toUpperCase()}
              </span>
              <a
                className="btn-primary mt-5"
                href={brochure}
                download="brochure.pdf"
                target="_blank"
                rel="noreferrer"
              >
                {footerText.downloadBrochure[language].toUpperCase()}
              </a>
            </div>
          </div>
        </div>
        <img
          src={Texture}
          alt="Textura"
          className="w-full h-[100px] object-fill sm:h-[110px] object-top mt-8 lg:hidden"
        />
      </div>
      <div className=" w-full text-[11px] lg:text-[14px] bg-black py-5 tracking-[0.08em] font-segoe">
        <div className="max-w-[1500px] mx-auto flex flex-col sm:flex-row sm:justify-center lg:justify-start items-center lg:pl-[107px]">
          <span
            className="text-white sm:mr-5 cursor-pointer hover:underline"
            onClick={() => history.push('/politicas-y-condiciones')}
          >
            {footerText.politics[language]}
          </span>
          <span
            className="text-white mt-2 sm:mt-0 sm:ml-5 cursor-pointer hover:underline"
            onClick={() => history.push('/garantias')}
          >
            {footerText.garanties[language]}
          </span>
        </div>
      </div>
    </div>
  )
}

export default Footer
