type bannerProps = {
  button: { es: string; en: string } | any
}

export const buttonBanner: bannerProps = {
  button: {
    es: 'Quiero saber más',
    en: 'I want to know more'
  }
}
