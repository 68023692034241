import React, { useContext } from 'react'
import Banner from '../../general/banner/Banner'
import qualityBanner from '../../../assets/images/qualityBanner.png'
import { companies, introQuality } from '../data'
import GeneralContext from '../../general/generalContext/GeneralContext'

const Intro = () => {
  const { language } = useContext(GeneralContext)
  return (
    <>
      <Banner bgImage={qualityBanner} title={introQuality.title[language]} />
      <section className="py-[60px] w-full ">
        <div className="flex flex-col justify-center items-center m-auto font-segoe tracking-[0.04em] text-[14px] md:text-[16px] font-light leading-[29px] py-[60px] w-full lg:w-[883px] ">
          <p className="text-center w-[90%]">
            {introQuality.description[language]}
          </p>
        </div>
        <div className="bg-white py-[60px] w-full">
          <div className=" w-10/12 max-w-[1100px m-auto flex flex-col justify-center items-center space-y-8">
            <h3 className="font-town text-[23px] md:text-[30px] font-bold tracking-[0.15em] leading-[29px]">
              {introQuality.titleTwo[language]}
            </h3>
            <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-7 place-items-center gap-10 w-[180px] md:w-[300px] lg:w-[800px] pt-7">
              {companies.map((company) => (
                <img
                  key={company.id}
                  className="flex w-full"
                  src={company.image}
                  alt={company.title}
                />
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Intro
