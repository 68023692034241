import MC from './../../assets/images/homepage/MC.png'
import CCC from './../../assets/images/homepage/CCC.png'
import CCS from './../../assets/images/homepage/CCS.png'
import COFACE from './../../assets/images/homepage/COFACE.png'
import EASA from './../../assets/images/homepage/EASA.jpeg'
import GIMI from './../../assets/images/homepage/GIMI.png'
import RUC from './../../assets/images/homepage/RUC.png'
import CSSD from './../../assets/images/homepage/CSSD.png'
import homepageText from './homepageText'
import { useContext } from 'react'
import GeneralContext from '../general/generalContext/GeneralContext'

const text: any = homepageText.certificateCompanies

const certificates: string[] = [CCS, MC, COFACE, CCC, EASA, CSSD, RUC, GIMI]

const Certificates = () => {
  const { language } = useContext(GeneralContext)
  return (
    <div className="w-full pt-[40px] pb-[60px] max-w-[1500px] mx-auto">
      <h2 className="font-town text-[23px] tracking-[0.15em] leading-8 text-center font-bold">
        {text.title[language]}
      </h2>
      <div
        className="rounded-lg bg-white flex flex-wrap justify-center w-[85%] mt-[60px] mx-auto py-[10px]"
        style={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}
      >
        {certificates.map((item, id) => {
          return (
            <img
              src={item}
              alt={`Certificado ${id}`}
              key={`Certificado ${id}`}
              className="px-8 my-[20px] w-auto h-16"
            />
          )
        })}
      </div>
    </div>
  )
}

export default Certificates
