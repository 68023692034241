interface IPolitics {
  headline: {
    es: string
    en: string
  }
  paragraphOne: {
    es: string
    en: string
  }
  paragraphTwo: {
    es: string
    en: string
  }
  paragraphThree: {
    es: string
    en: string
  }
  paragraphFour: {
    es: string
    en: string
  }
  paragraphFive: {
    es: string
    en: string
  }
  paragraphSix: {
    es: string
    en: string
  }
  paragraphSeven: {
    es: string
    en: string
  }
}

export const textPolitics: IPolitics = {
  headline: {
    es: 'Políticas y condiciones generales',
    en: ''
  },
  paragraphOne: {
    es: 'L.K.S. es una empresa  legalmente establecida de régimen común , no es gran contribuyente ni auto retenedor. Opera a nivel nacional e internacional según sea el proyecto a desarrollar. ',
    en: ''
  },
  paragraphTwo: {
    es: 'Su actividad económica principal es prestar el servicio de mantenimiento, reparación de toda clase de equipos Electro industriales  y las demás actividades comerciales relacionadas con esta actividad económica.',
    en: ''
  },
  paragraphThree: {
    es: 'Las condiciones de pago establecidas usualmente  son con un anticipo de un 50% para iniciar las operaciones para valores mayores o iguales a 10 salarios mínimos mensuales vigentes y el 50 % a la entrega  del servicio  pactado, o de acuerdo a las condiciones particulares de venta establecidas en la cotización inicial. A esta condición se exceptúa los contratos de obra los cuales son regidos por condiciones especificas según el proyecto a ejecutar. Para facturas  con valores menores a 10 SMMLV el pago será 100% a la entrega  del servicio  pactado, o de acuerdo a las condiciones particulares de venta establecidas en la cotización inicial.',
    en: ''
  },
  paragraphFour: {
    es: 'Los pedidos a ejecutar deben tener una orden de servicio emitida por la empresa contratante y suscrita  por  el personal autorizado para este fin. La orden de servicio será  enviada  al correo electrónico   comercial@lks.com.co.',
    en: ''
  },
  paragraphFive: {
    es: 'Todo diagnóstico preliminar o definitivo, informe o concepto técnico consultado a L.K.S. tiene costo a menos que se haya estipulado específicamente en la oferta que está incluido dentro del servicio cotizado. Todo trabajo que ingresa a L.K.S. para diagnóstico, mantenimiento o reparación, tiene como tiempo mínimo de ejecución 24 horas hábiles.',
    en: ''
  },
  paragraphSix: {
    es: 'En caso de que el cliente no acepte reparar o autorizar un mantenimiento después de presentada la cotización, este asumirá el costo del diagnóstico o informe preliminar presentado, se cobrará el valor establecido mediante factura o cuenta de cobro según el caso y será asumido directamente por el cliente o en su defecto por el personal autorizado para este fin y deberá retirar  en el menor tiempo posible el motor o equipo dejado en nuestras instalaciones. DAÑOS CONSECUENCIALES. LKS ni el Cliente serán responsables ante el otro por daños indirectos, especiales, punitivos, ejemplares o consecuenciales, incluyendo, enunciativamente, daños por pérdida de producción, pérdida de ingresos, pérdida de producto, lucro cesante, pérdida o interrupción de negocios independientemente de la negligencia individual, conjunta y/o actual de cualquiera de las partes y cada parte por este medio libera a la otra por este concepto.',
    en: ''
  },
  paragraphSeven: {
    es: 'Luego de haberse cotizado la venta o el servicio  de mantenimiento  ya sea reparado o no, y el cliente por razones ajenas a L.K.S. deja su equipo en nuestra instalaciones por más de 60 días calendario sin ningún tipo de comunicación o información que cambie esta condición contados desde la fecha de la cotización o factura presentada,  L.K.S. lo declarará automáticamente en estado de abandono  y será enviado a una bodega destinada para tal fin, donde permanecerá  por un tiempo máximo de 90 días calendario en esa condición de abandono. Los costos de dicho bodegaje más los costos de transporte en que se incurra para la movilización del motor o equipo serán  a cargo y responsabilidad del cliente, los cuales serán pagados antes de retirar el equipo. L.K.S. no responderá EN NINGÚN CASO por el motor o equipo declarado en  estado  de ABANDONO. ',
    en: ''
  }
}
