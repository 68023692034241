import InformativeSection from './InformativeSection'
import { useContext, useEffect, useRef, useState } from 'react'
import { services, textButtonSection } from './data'
import GeneralContext from '../general/generalContext/GeneralContext'

const CarouselServices = () => {
  const { language } = useContext(GeneralContext)
  const [position, setPosition] = useState(0)
  const intervalRef = useRef<number | any>(null)

  const handleMove = (left: boolean) => {
    if (left && position > 0) setPosition((position - 1) % services.length)
    else if (left && position === 0) setPosition(services.length - 1)
    else if (!left) setPosition((position + 1) % services.length)
  }

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setPosition((pos) => (pos + 1) % services.length)
    }, 10000)
    return () => {
      clearInterval(intervalRef.current)
    }
  }, [])

  return (
    <div className="w-full py-[40px] bg-white">
      <div className="max-w-[1500px] mx-auto">
        <div
          className="flex sm:hidden overflow-x-auto max-w-full px-[19px] ml-[11px]"
          style={{ scrollSnapType: 'x mandatory' }}
        >
          {services.map((service: any, index: number) => (
            <div
              key={index}
              className="w-[280px] px-[11px] min-w-[280px]"
              style={{ scrollSnapAlign: 'start' }}
            >
              <InformativeSection
                textButton={textButtonSection[language]}
                img={service.img}
                title={service.title[language]}
                text={[service.text[language]]}
                imgLeft={false}
                handleClick={() => {
                  console.log(1)
                }}
              />
            </div>
          ))}
        </div>
        <div className="hidden sm:flex px-[30px] py-[40px] sm:px-[40px] lg:px-[100px] lg:py-[60px]">
          <InformativeSection
            textButton={textButtonSection[language]}
            img={services[position].img}
            title={services[position].title[language]}
            text={[services[position].text[language]]}
            imgLeft={false}
            handleClick={() => {
              console.log(1)
            }}
            handleMove={handleMove}
          />
        </div>
      </div>
    </div>
  )
}

export default CarouselServices
