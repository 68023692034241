import { useState } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Footer from './screens/general/footer/Footer'
import GeneralContext from './screens/general/generalContext/GeneralContext'
import Header from './screens/general/header/Header'
import LoadingIndicator from './screens/general/loadingIndicator/LoadingIndicator'
import Menu from './screens/general/menu/Menu'
import NewsPublish from './screens/publicaciones/components/NewsPublish'
import './styles/main.sass'
import Contact from './screens/general/contact/Contact'
import Security from './screens/security/Security'
import Homepage from './screens/homepage/Homepage'
import Quality from './screens/quality/Quality'
import Services from './screens/services/Services'
import Innovation from './screens/innovation/Innovation'
import Politics from './screens/policies/Politics'
import Guarantee from './screens/guarantee/Guarantee'
import News from './screens/publicaciones/News'
import OurCompany from './screens/ourCompany/OurCompany'
import Prestige from './screens/prestige/Prestige'
import ScrollToTop from './screens/general/ScrollToTop'

const App = (): any => {
  const [loading, setLoading] = useState<boolean>(false)
  const [language, setLanguage] = useState<string>('es')
  const [headerColor, setHeaderColor] = useState<string>('white')
  const [menu, setMenu] = useState<boolean>(false)
  const [contact, setContact] = useState<boolean>(false)

  return (
    <GeneralContext.Provider
      value={{
        runLoading: () => setLoading(true),
        stopLoading: () => setLoading(false),
        toggleLanguage: () => setLanguage(language === 'es' ? 'en' : 'es'),
        language: language,
        toggleMenu: (toggle: boolean) => setMenu(toggle),
        toggleContact: (toggle: boolean) => setContact(toggle),
        headerColor: headerColor,
        handleHeaderColor: (color: string) => setHeaderColor(color)
      }}
    >
      <BrowserRouter>
        <Header />
        <Menu open={menu} />
        <Contact open={contact} />
        <ScrollToTop />
        <Switch>
          <Route path="/publicaciones/:id" component={NewsPublish} />
          <Route path="/publicaciones" component={News} />
          <Route path="/seguridad" component={Security} />
          <Route path="/que-hacemos" component={Services} />
          <Route path="/calidad" component={Quality} />
          <Route path="/politicas-y-condiciones" component={Politics} />
          <Route path="/garantias" component={Guarantee} />
          <Route path="/innovacion" component={Innovation} />
          <Route path="/prestigio" component={Prestige} />
          <Route path="/nuestra-empresa" component={OurCompany} />
          <Route path="/" component={Homepage} />
        </Switch>
        <Footer />
      </BrowserRouter>
      {loading && <LoadingIndicator />}
    </GeneralContext.Provider>
  )
}

export default App
