const HistoryCard = ({ img, text }: { img: string; text: string }) => {
  return (
    <div className="w-[330px] pr-[30px]">
      <img src={img} alt="Carta historia" className="w-[300px] h-[300px] rounded-lg object-cover" loading="lazy" />
      <p className="mt-[30px] font-segoe text-[15px] leading-6 tracking-[0.03em] font-semiLight max-h-28 overflow-y-auto scrollbar scrollbar-thumb">
        {text}
      </p>
    </div>
  )
}

export default HistoryCard
