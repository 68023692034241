type contact = {
  bogotaTitle: { es: string; en: string } | string | any
  addressBogota: string
  pbxBogota: string
  phoneBogota: { es: string; en: string } | string | any
  ctaWhatsapp: { es: string; en: string } | string | any
  cartagenaTitle: string
  addressCartagena: string
  pbxCartagena: string
  phoneCartagena: { es: string; en: string } | string | any
  downloadBrochure: { es: string; en: string } | string | any
  mail: { es: string; en: string } | string | any
}

export const contactData: contact = {
  bogotaTitle: {
    es: 'Bogotá',
    en: 'Bogota'
  },
  addressBogota: 'Calle 24 # 24 – 17 Barrio Samper Mendoza',
  pbxBogota: 'PBX: +57 (1) 702 4589; +57 ( 1) 702 7791',
  phoneBogota: {
    es: 'Cel.: +57 301 787 9332',
    en: 'Phone.: +57 301 787 9332'
  },
  ctaWhatsapp: {
    es: 'ESCRÍBENOS POR WHATSAPP',
    en: 'WRITE US BY WHATSAPP'
  },
  cartagenaTitle: 'Cartagena',
  addressCartagena: 'Diagonal 21 A # 55 – 64 Barrio el Bosque',
  pbxCartagena: 'PBX: +57 (5) 693 3378',
  phoneCartagena: {
    es: 'Cel.: +57 317 894 4281',
    en: 'Phone.: +57 317 894 4281'
  },
  downloadBrochure: {
    es: 'DESCARGAR BROCHURE',
    en: 'DOWNLOAD BROCHURE'
  },
  mail: {
    es: 'CORREOS ELECTRÓNICOS',
    en: 'EMAIL'
  }
}

type typeFormData = {
  thanks: { es: string; en: string } | string | any
  userMessage: { es: string; en: string } | string | any
  contactText: { es: string; en: string } | string | any
  quote: { es: string; en: string } | string | any
  formName: { es: string; en: string } | string | any
  formEmail: { es: string; en: string } | string | any
  formCity: { es: string; en: string } | string | any
  formCompany: { es: string; en: string } | string | any
  formTag: string
  formTeam: { es: string; en: string } | string | any
  formPower: { es: string; en: string } | string | any
  formVoltage: { es: string; en: string } | string | any
  formNumber: { es: string; en: string } | string | any
  formDescription: { es: string; en: string } | string | any
  formButton: { es: string; en: string } | string | any
  quoteWithUs: { es: string; en: string } | string | any
}

export const formData: typeFormData = {
  thanks: {
    es: 'GRACIAS!',
    en: 'THANKS!'
  },
  userMessage: {
    es: 'Recibimos tu mensaje, nos contactaremos contigo lo antes posible.',
    en: 'We received your message, we will contact you as soon as possible.'
  },
  contactText: {
    es: 'CONTÁCTANOS',
    en: 'CONTACT US'
  },
  quote: {
    es: 'COTIZA',
    en: 'QUOTE'
  },
  quoteWithUs: {
    es: 'CON NOSOTROS',
    en: 'WITH US'
  },
  formName: {
    es: 'Nombre',
    en: 'Name'
  },
  formEmail: {
    es: 'Correo electrónico',
    en: 'Email'
  },
  formCity: {
    es: 'Ciudad',
    en: 'City'
  },
  formCompany: {
    es: 'Empresa',
    en: 'Company'
  },
  formTag: 'TAG',
  formTeam: {
    es: 'Tipo de equipo',
    en: 'Team type'
  },
  formPower: {
    es: 'Potencia',
    en: 'Power'
  },
  formVoltage: {
    es: 'Voltaje',
    en: 'Voltage'
  },
  formNumber: {
    es: 'Número de contacto',
    en: 'Contact number'
  },
  formDescription: {
    es: 'Descripción',
    en: 'Description'
  },
  formButton: {
    es: 'ENVIAR',
    en: 'SENT'
  }
}
