import React, { useContext } from 'react'
import GeneralContext from '../general/generalContext/GeneralContext'
import Certificates from './components/CertificatesPrestige'
import FirstPrestigeSection from './components/FirstPrestigeSection'
import IntroPrestige from './components/IntroPrestige'
import Testimonials from './components/Testimonials'

const Prestige = () => {
  const { language } = useContext(GeneralContext)
  return (
    <div>
      <IntroPrestige language={language} />
      <FirstPrestigeSection language={language} />
      <Certificates language={language} />
      <Testimonials language={language} />
    </div>
  )
}

export default Prestige
