import React, { useEffect } from 'react'
import { RiseLoader } from 'react-spinners'

const LoadingIndicator = () => {
  
  useEffect(() => {
    document.body.style.overflowY = "hidden";

    return () => {
      document.body.style.overflowY = "auto";      
    }
  })

  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-black bg-opacity-20 z-50">
      <RiseLoader size={15} color={'#2f42da'} />
    </div>
  )
}

export default LoadingIndicator
