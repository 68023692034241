import { useContext } from 'react'
import GeneralContext from '../general/generalContext/GeneralContext'
import Intro from './components/Intro'
import MisionVision from './components/MisionVision'
import Values from './components/Values'

const Security = () => {
  const { language } = useContext(GeneralContext)
  return (
    <div className=" space-y-20 bg-greyBg">
      <Intro language={language} />
      <Values language={language} />
      <MisionVision language={language} />
    </div>
  )
}

export default Security
