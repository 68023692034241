import { useContext } from 'react'
import { useHistory } from 'react-router'
import GeneralContext from '../general/generalContext/GeneralContext'

const PostCard = ({ card }: { card: any }) => {
  const { language }: any = useContext(GeneralContext)
  const history = useHistory()
  return (
    <div
      className="rounded-lg pb-14 w-[280px] min-w-[280px] lg:w-[380px] lg:min-w-[380px] hover:scale-105 transition duration-300 cursor-pointer"
      style={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}
      onClick={() => history.push('/publicaciones/' + card.id)}
    >
      <img
        src={card.img}
        alt={card.title[language]}
        className="w-full h-[223px] lg:h-[292px] rounded-t-lg"
      />
      <h4 className="font-segoe font-semiLight text-[15px] tracking-wide leading-6 mt-3 px-5">
        {card.date[language]}
      </h4>
      <h3 className="font-segoe font-bold tracking-[0.15em] text-xl mt-1 px-5">
        {card.title[language]}
      </h3>
    </div>
  )
}

export default PostCard
