interface IGuarantee {
  headline: {
    es: string
    en: string
  }
  paragraphOne: {
    es: string
    en: string
  }
  paragraphTwo: {
    es: string
    en: string
  }
  paragraphThree: {
    es: string
    en: string
  }
  paragraphFour: {
    es: string
    en: string
  }
  paragraphFive: {
    es: string
    en: string
  }
  paragraphSix: {
    es: string
    en: string
  }
  paragraphSeven: {
    es: string
    en: string
  }
  paragraphEight: {
    es: string
    en: string
  }
}

export const textGuarantee: IGuarantee = {
  headline: {
    es: 'Garantías del servicio ',
    en: ''
  },
  paragraphOne: {
    es: 'L.K.S. es una empresa colombiana que es reconocida en el mercado por más de 53 años, ofreciendo los servicios técnicos para las industrias del país y de las extranjeras que operan dentro del territorio colombiano. Cuando usted trae su motor o equipo eléctrico a nuestra empresa para el mantenimiento, esta obteniendo  un incomparable “know-how “ desarrollado a través de estos largos años de existencia.',
    en: ''
  },
  paragraphTwo: {
    es: 'Nuestro personal está en permanente capacitación para ofrecerle  el mejor servicio de mantenimiento y reparación de los equipos eléctricos de su industria.',
    en: ''
  },
  paragraphThree: {
    es: 'De igual manera por nuestra experiencia y los servicios autorizados  por los fabricantes de motores y equipos, todos los insumos y materiales empleados en el mantenimiento y reparación de los motores y equipos, cumplen con las normas técnicas dictadas por las empresas fabricantes, apoyadas por las respectivas fichas técnicas y de seguridad en el manejo de estos materiales.',
    en: ''
  },
  paragraphFour: {
    es: 'Todos nuestros procedimientos están claramente establecidos  mediante protocolos  de acuerdo a nuestro sistema de gestión y control de calidad certificado por ICONTEC norma  NTC -ISO 9001.-2008',
    en: ''
  },
  paragraphFive: {
    es: 'Nuestra labor está controlada por el sistema de gestión  en seguridad industrial y salud  en  el trabajo  certificada  por ICONTEC norma OHSAS 18001-2007 que garantiza que todos los procedimientos desarrollados por L.K.S. son seguros para nuestros  clientes y trabajadores.',
    en: ''
  },
  paragraphSix: {
    es: 'El alcance del mantenimiento o la reparación  del cual hacemos nuestra garantía, esta directamente relacionado con el ofrecimiento pactado en la orden de compra o pedido solicitado y que cumple específicamente con lo cotizado. Se da por entendido que el cliente al aceptar la cotización, o al emitir la orden de trabajo o servicio, declara conocer y admitir este Acuerdo de Garantías.',
    en: ''
  },
  paragraphSeven: {
    es: 'Los motores o equipos reparados o que salen del mantenimiento, son probados de manera exhaustiva  tanto en su parte eléctrica  como en su parte mecánica, para garantizar al cliente un funcionamiento óptimo cumpliendo con las especificaciones determinadas en la placa del equipo. De esto queda constancia escrita en los protocolos e informes finales de pruebas con los cuales se entregan los equipos que salen de nuestras instalaciones.',
    en: ''
  },
  paragraphEight: {
    es: 'Esta garantía no ampara ningún tipo de riesgo a los que están expuestos los motores o equipos reparados. Estos son algunos de los riesgos contemplados:  sobrecarga tanto mecánica como eléctrica, variaciones en el voltaje o frecuencia al que sea sometido, operar el equipo sin las condiciones mínimas de seguridad  mantenimiento y protección al cual debe estar programado y de acuerdo a las indicaciones de garantía entregadas por el fabricante. Sobrecargas originadas por rayos o condiciones atmosféricas adversas, manipulación por personal no calificado, Instalaciones eléctricas y/o mecánicas defectuosas o fuera de norma, calidad de la energía o desbalanceo entre fases. Respecto de los elementos rodantes tales como rodamientos, chumaceras, cojinetes, bujes, L.K.S. no da garantías, puesto que son elementos  que por su función no permiten establecer su condición  después de puesta en marcha el motor o equipo , pues una vez ya instalados en el equipo han cumplido las normas de funcionamiento y han pasado todas las pruebas realizadas  antes de la entrega final.',
    en: ''
  }
}
