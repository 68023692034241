type props = {
  title: string
  text: string[]
  decoration?: boolean
  img: string
  handleClick?: () => void
  imgLeft: boolean
  handleMove?: (left: boolean) => void
  textButton?: string
}

const InformativeSection = ({
  title,
  text,
  decoration,
  img,
  handleClick,
  imgLeft,
  textButton,
  handleMove
}: props) => {
  return (
    <div
      className={
        imgLeft
          ? 'w-full flex flex-col sm:flex-row sm:justify-between max-w-[1400px]'
          : 'w-full flex flex-col sm:flex-row-reverse sm:justify-between max-w-[1400px]'
      }
    >
      <img
        src={img}
        alt={title}
        style={{ filter: 'grayscale(100%)' }}
        className="w-full lg:w-[700px] max-h-[300px] sm:max-h-full sm:max-w-[55%] lg:max-w-[60%] h-full lg:h-auto rounded-md object-cover lg:self-center img-semi-square xl:img-semi-rectangle"
      />
      <div className="w-full sm:w-max sm:max-w-[40%] lg:max-w-[35%] lg:mt-[50px]">
        {handleMove && (
          <div className="hidden sm:flex w-[115px] justify-between mb-[30px]">
            <button className="arrow-left" onClick={() => handleMove(true)} />
            <button className="arrow-right" onClick={() => handleMove(false)} />
          </div>
        )}
        <h2 className="font-bold text-[23px] lg:text-[30px] font-town tracking-[0.15em] leading-9 mt-[25px] sm:mt-0 w-[80%]">
          {title}
        </h2>
        <p
          className={`font-semiLight text-base leading-7 tracking-[0.04em] mt-[25px] sm:mt-[10px] lg:mt-[20px] flex flex-col ${
            decoration ? 'bullets' : ''
          }`}
        >
          {text.map((item, key) => (
            <span key={key}>{item}</span>
          ))}
        </p>
        {handleClick && (
          <button
            onClick={() => handleClick()}
            className="btn-primary mt-[25px] sm:mt-[30px]"
          >
            {textButton}
          </button>
        )}
      </div>
    </div>
  )
}

export default InformativeSection
