import HistoryCard from './HistoryCard'
import events from './EventsHistory'
import { useHistory } from 'react-router'
import Today from './../../assets/images/ourCompany/history/Hoy.jpeg'
import { historyData } from './data'

const History = ({ language }: any) => {
  const history = useHistory()

  return (
    <div className="pt-[60px] w-full max-w-[1500px] mx-auto">
      <h2 className="font-town text-[23px] left-8 tracking-[0.15em] font-bold pl-[30px]">
        {historyData.title[language]}
      </h2>
      <div
        id="history-scroll"
        className="flex max-w-full overflow-x-auto pt-[60px] pb-[60px] scrollbar-thumb scrollbar pl-[15px]"
      >
        {events.map((event: any) => (
          <div className="pl-[15px]" key={event.title}>
            <h3 className="font-town font-bold text-[23px] leading-8 tracking-[0.15em]">
              {event.title}
            </h3>
            <div className="flex mt-[31px]">
              {event.cards.map((card: any, id: any) => (
                <HistoryCard
                  img={card.img}
                  text={card.text[language]}
                  key={event.title + id}
                />
              ))}
            </div>
          </div>
        ))}
        <div className="pl-[15px]">
          <h3 className="font-town font-bold text-[23px] leading-8 tracking-[0.15em]">
            {historyData.today[language]}
          </h3>
          <div className="flex mt-[31px]">
            <div className="w-max pr-[30px]">
              <img
                src={Today}
                alt="Carta historia"
                className="w-[300px] h-[300px] rounded-lg object-cover"
              />
              <div
                className="btn-primary mt-[30px]"
                style={{ scrollSnapType: 'x mandatory', height: 'max-content' }}
                onClick={() => history.push('/publicaciones')}
              >
                {historyData.posts[language]}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default History
