import { useContext, useState } from 'react'
import GeneralContext from '../../general/generalContext/GeneralContext'
import InfoService from './InfoService'

const ServiceCards = ({ activeCategory }: any) => {
  const { language } = useContext(GeneralContext)
  const [activeService, setActiveService] = useState(null)

  return (
    <div className="container m-auto w-10/12 grid grid-cols-1 md:grid-cols-cardValue  gap-12 pt-[60px] relative">
      {activeService && (
        <InfoService
          activeService={activeService}
          handleClose={() => setActiveService(null)}
        />
      )}
      {activeCategory.info?.map((service: any, index: number) => {
        return (
          <div
            key={index}
            className="font-segoe flex flex-col justify-center rounded-lg shadow-xl mb-5 hover:scale-105 transition duration-300 cursor-pointer h-[380px] group"
            onClick={() => setActiveService(service)}
          >
            <div className=" relative rounded-t-lg">
              <img
                src={service.img}
                className="mix-blend-overly filter grayscale hover:filter-none  w-full  max-w-full h-[274px] object-cover bg-cover bg-center rounded-t-lg"
                alt={service?.description[language]}
              />
            </div>
            <span className="font-segoe text-[12px] lg:text-[14px] h-[85px] py-2 grid place-items-center font-light text-center tracking-[0.06em] p-5 group-hover:font-medium">
              {service?.description[language]}
            </span>
          </div>
        )
      })}
    </div>
  )
}

export default ServiceCards
