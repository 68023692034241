export const ourCompanyData: any = {
  title: {
    es: 'Nuestra Empresa',
    en: 'Our Company'
  },
  description: {
    es: ' Somos LA FAMILIA LKS. La gran mayoría de empresas y talleres de reparación de motores eléctricos a nivel nacional e internacionalsiempre van representados con la integración de una familia de padres, hermanos e hijos interactuando en equipo en las diferentes especialidades de nuestro ramo de la electrotecnia, la herencia de la experiencia técnica y humana con nuestros trabajadores, compartir el conocimiento teórico e innovador tecnológico respecto al bello arte de la manualidad y filigrana de los bobinados es lo que ha siempre diferenciado y el reconocimiento de nuestra empresa por más de 50 años como parte de los retos que se nos presentan continuamente en estos avances tecnológicos de ingeniería.',
    en: 'We are LKS. The majority of electric motor repair shops and manufacturers in the country and internationally are represented with the integration of a family of parents, brothers and children working in team in the different specialties of our electrical machinery, the heritage of the technical and human experience with our employees, sharing the theoretical and innovative technological knowledge regarding the beautiful art of the manuality and engraving of the bobinado is what has always distinguished and the recognition of our company for more than 50 years as part of the challenges that we are constantly encountering in these technological advances in engineering.'
  },
  descriptionTwo: {
    es: 'LKS ha asegurado un destacado lugar en el mercado, siendo líderes en el mantenimiento industrial gracias a la calidad, la experiencia de sus trabajadores, su amplia expansión de servicios, la comunicación con fabricantes y la atención personalizada con sus clientes.',
    en: 'LKS has guaranteed a distinguished place in the market, being leaders in the industrial maintenance thanks to the quality, the experience of its employees, its wide expansion of services, the communication with manufacturers and the personalized attention with its customers.'
  }
}

export const agregateValueData: any = {
  title: {
    es: 'Valor agregado',
    en: 'Agregate value'
  }
}

export const historyData: any = {
  title: {
    es: 'Historia',
    en: 'History'
  },
  today: {
    es: 'Hoy',
    en: 'Today'
  },
  posts: {
    es: 'MIRA NUESTRAS PUBLICACIONES',
    en: 'SEE OUR POSTS'
  }
}

export const items: any = [
  {
    es: 'Atención de emergencias 24 horas 7 días de la semana ',
    en: '24 hour emergency service 7 days a week'
  },
  {
    es: 'Materiales de alta calidad',
    en: 'High quality materials'
  },
  {
    es: 'Equipos calibrados',
    en: 'Calibrated equipment'
  },
  {
    es: 'Conocimiento',
    en: 'Knowledge'
  },
  {
    es: 'Personal calificado para atender equipos especializados de todas las potencias',
    en: 'Qualified personnel to handle specialized equipment of all powers'
  },
  {
    es: 'Comunicación directa con firmas fabricantes para mantener el diseño original y garantías de fábrica',
    en: 'Direct communication with manufacturers to maintain the original design and manufacturer guarantees'
  },
  {
    es: 'Disponibilidad para atender servicios en el exterior',
    en: 'Availability to handle services in the outside'
  }
]
