import Cement from './../../assets/images/ourCompany/cement.jpg'
import Energy from './../../assets/images/ourCompany/energy.jpg'
import Food from './../../assets/images/ourCompany/food.jpeg'
import Hospital from './../../assets/images/ourCompany/hospital.jpg'
import Military from './../../assets/images/ourCompany/military.jpg'
import Mining from './../../assets/images/ourCompany/mining.jpg'
import OilGas from './../../assets/images/ourCompany/oilGas.jpeg'
import Paper from './../../assets/images/ourCompany/paper.jpg'
import Petrochemical from './../../assets/images/ourCompany/petrochemical.jpeg'
import PublicServices from './../../assets/images/ourCompany/publicServices.jpg'
import ThemePark from './../../assets/images/ourCompany/themePark.jpg'
import Metallurgical from './../../assets/images/ourCompany/metalurgia.jpg'

import IndustryCard from './IndustryCard'

const items: Array<{ title: { es: string; en: string }; img: string }> = [
  {
    title: {
      es: 'PETROLEO & GAS',
      en: 'OIL & GAS'
    },
    img: OilGas
  },
  {
    title: {
      es: 'PETROQUÍMICO',
      en: 'PETROCHEMICAL'
    },
    img: Petrochemical
  },
  {
    title: {
      es: 'ALIMENTOS',
      en: 'FOOD'
    },
    img: Food
  },
  {
    title: {
      es: 'CEMENTERO',
      en: 'CEMENT'
    },
    img: Cement
  },
  {
    title: {
      es: 'MINERIA',
      en: 'MINING'
    },
    img: Mining
  },
  {
    title: {
      es: 'GENERACIÓN DE ENERGÍA',
      en: 'ENERGY GENERATION'
    },
    img: Energy
  },
  {
    title: {
      es: 'SERVICIOS PÚBLICOS',
      en: 'PUBLIC SERVICES'
    },
    img: PublicServices
  },
  {
    title: {
      es: 'METALÚRGICA',
      en: 'METALLURGICAL'
    },
    img: Metallurgical
  },
  {
    title: {
      es: 'PAPELES Y CARTÓN',
      en: 'PAPER & CARD'
    },
    img: Paper
  },
  {
    title: {
      es: 'HOSPITALES',
      en: 'HOSPITALS'
    },
    img: ThemePark
  },
  {
    title: {
      es: 'HOSPITALES',
      en: 'HOSPITALS'
    },
    img: Hospital
  },
  {
    title: {
      es: 'MILITAR Y DEFENSA',
      en: 'MILITARY & DEFENSE'
    },
    img: Military
  }
]

const Industries = ({ language }: any) => {
  return (
    <div className="max-w-[1500px] mx-auto">
      <h3 className="w-full pl-6 sm:pl-11 font-town font-bold text-[23px] sm:text-[25px] lg:text-[30px] leading-7 tracking-[0.15em] py-10">
        INDUSTRIAS QUE ATENDEMOS
      </h3>
      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 px-6 sm:px-11 justify-items-center gap-x-4 lg:gap-x-12">
        {items.map((item: any, index: number) => (
          <IndustryCard
            title={item.title[language]}
            img={item.img}
            key={index}
          />
        ))}
      </div>
    </div>
  )
}

export default Industries
