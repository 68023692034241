const types: string[] = [
  'Motor DC',
  'Motor AC',
  'Generador',
  'Bomba sumergible',
  'Transformador',
  'Reductor',
  'Servomotor',
  'Motoreductor',
  'Otro'
]

export default types
