import HomeBanner from './HomeBanner'
import InformativeSection from './InformativeSection'
import Specialists from './../../assets/images/homepage/WeAreSpecialists.jpg'
import OurMotor from './../../assets/images/homepage/ourMotor.jpg'
import CarouselServices from './CarouselServices'
import Certificates from './Certificates'
import CarouselPosts from './CarouselPosts'
import BottomBanner from './BottomBanner'
import { useHistory } from 'react-router'
import { useContext } from 'react'
import GeneralContext from '../general/generalContext/GeneralContext'
import homepageText from './homepageText'

const textSection: any = homepageText.informativeSection
const textMotor: any = homepageText.informativeSectionOurMotor
const textButton: any = homepageText.homeButton
const textCards: any = homepageText.postsCardsText
const bottomText: any = homepageText.bottomText

const Homepage = () => {
  const { language } = useContext(GeneralContext)

  const history = useHistory()

  return (
    <div className="overflow-x-hidden">
      <HomeBanner />
      <div className="px-[30px] py-[40px] sm:px-[40px] lg:px-[100px] lg:py-[60px] max-w-[1500px] mx-auto">
        <InformativeSection
          title={textSection.title[language]}
          decoration={true}
          text={textSection.characteres[language]}
          img={Specialists}
          imgLeft={true}
        />
      </div>
      <CarouselServices />
      <Certificates />
      <div className="bg-white">
        <div className="max-w-[1500px] mx-auto px-[30px] py-[40px] sm:px-[40px] lg:px-[100px] lg:py-[60px] ">
          <InformativeSection
            title={textMotor.title[language]}
            text={[textMotor.description[language]]}
            img={OurMotor}
            handleClick={() => {
              history.push('/nuestra-empresa')
            }}
            imgLeft={false}
            textButton={textButton.text[language]}
          />
        </div>
      </div>
      <CarouselPosts title={textCards.title[language]} />
      <BottomBanner title={bottomText.title[language]} />
    </div>
  )
}

export default Homepage
