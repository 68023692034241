import { certificatesLks, textCertificate } from '../data'

const CertificatesPrestige = ({ language }: any) => {
  return (
    <div className="container m-auto pt-[60px]">
      <h2 className="uppercase font-town tracking-[0.15em] text-[30px] text-center flex justify-center items-center font-bold pb-5">
        {textCertificate[language]}
      </h2>

      <div className="flex flex-wrap justify-center items-center w-11/12 m-auto gap-6 mt-16">
        {certificatesLks.map((image: any) => {
          return (
            <div className=" w-full max-w-[350px]" key={image.id}>
              <img
                className="w-full rounded-lg shadow-xl hover:scale-105 transition duration-200"
                src={image.certificate}
                alt="certificado"
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default CertificatesPrestige
