import React, { useContext } from 'react'
import Banner from '../general/banner/Banner'
import NewsPrincipal from './components/NewsPrincipal'
import bannerNews from '../../assets/images/publish/publishCover.png'
import { cover } from './data'
import GeneralContext from '../general/generalContext/GeneralContext'

const News: React.FC = () => {
  const { language } = useContext(GeneralContext)

  return (
    <div>
      <Banner bgImage={bannerNews} title={language === 'es'? 'Publicaciones': 'Publications'} />
      <NewsPrincipal cover={cover} />
    </div>
  )
}

export default News
