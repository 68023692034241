import CardImg from '../../assets/images/homepage/PostCard.jpg'
import PostCard from './PostCard'

type Card = {
  img: string
  date?: { es: string; en: string }
  title: { es: string; en: string }
  id: number
}

const cards: Card[] = [
  {
    img: CardImg,
    date: {
      es: 'Enero 12, 2021',
      en: 'January 12, 2021'
    },
    title: {
      es: 'Título del artículo',
      en: 'Title of the article'
    },
    id: 1
  },
  {
    img: CardImg,
    date: {
      es: 'Enero 12, 2021',
      en: 'January 12, 2021'
    },
    title: {
      es: 'Título del artículo',
      en: 'Title of the article'
    },
    id: 2
  },
  {
    img: CardImg,
    date: {
      es: 'Enero 12, 2021',
      en: 'January 12, 2021'
    },
    title: {
      es: 'Título del artículo',
      en: 'Title of the article'
    },
    id: 3
  }
]

const CarouselPosts = ({ title }: { title: string }) => {
  return (
    <div className="w-full py-[60px] max-w-[1500px] mx-auto">
      <h2 className="text-[25px] lg:text-[30px] leading-8 tracking-[0.15em] font-town font-bold px-[30px] lg:text-center sm:w-[60%] lg:w-full">
        {title}
      </h2>
      <div
        className="flex overflow-x-auto w-max max-w-full px-[19px] pb-5 pt-7 lg:mx-auto ml-[11px] scrollbar-thumb scrollbar"
        style={{ scrollSnapType: 'x mandatory' }}
      >
        {cards.map((item, index: number) => (
          <div
            className="px-[11px] lg:px-[24px]"
            key={index}
            style={{ scrollSnapAlign: 'start' }}
          >
            <PostCard card={item} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default CarouselPosts
