import { ReactNode } from 'react'
import IconCross from '../../../assets/cross.svg'

const InformativeModalResponsive = ({
  children,
  handleClose,
  type
}: {
  children: ReactNode
  type: string
  handleClose: () => void
}) => {
  return (
    <div className="fixed top-0 left-0 bottom-0 right-0 flex flex-col items-center justify-center z-10">
      <div
        className="absolute top-0 left-0 bottom-0 right-0 bg-black bg-opacity-40"
        onClick={() => handleClose()}
      />
      <div
        className={
          type === 'service'
            ? 'lg:max-w-[440px] container-modal-responsive'
            : 'lg:max-w-[440px] container-modal-responsive'
        }
      >
        <div
          className="rounded-bl-[9px] bg-[#C4C4C4] w-[66px] h-[61px] min-h-[61px] self-end flex items-center justify-center cursor-pointer z-20"
          onClick={() => handleClose()}
        >
          <img src={IconCross} alt="Close" />
        </div>
        <div className="mt-[-21px] px-[30px] sm:px-[40px] lg:px-[50px] z-10">
          {children}
        </div>
      </div>
    </div>
  )
}

export default InformativeModalResponsive
