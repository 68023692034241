// Servicios de planta
import serviceImage from '../../../assets/images/servicios/servicios-planta/ejemploServicios.png'
import bajaTension from '../../../assets/images/servicios/servicios-planta/baja-tension-1 .jpg'
import bobinadoMantenimiento from '../../../assets/images/servicios/servicios-planta/portada.jpg'
import motorVertical from '../../../assets/images/servicios/servicios-planta/motorVertical.jpg'
import generadores from '../../../assets/images/servicios/servicios-planta/GENERADORES.jpg'
import bombasSumergibles from '../../../assets/images/servicios/servicios-planta/bombasSumergibles.jpg'
import transformador from '../../../assets/images/servicios/servicios-planta/transformador.jpg'
import balanceoDinamico from '../../../assets/images/servicios/servicios-planta/balanceoDinamico.jpg'
import impulsoresVentiladores from '../../../assets/images/servicios/servicios-planta/impulsoresVentiladores.jpg'

import bajaTensionOne from '../../../assets/images/servicios/servicios-planta/baja-tension/1.jpg'
import bajaTensionTwo from '../../../assets/images/servicios/servicios-planta/baja-tension/2.jpg'
import bajaTensionThree from '../../../assets/images/servicios/servicios-planta/baja-tension/3.jpg'
import bajaTensionFour from '../../../assets/images/servicios/servicios-planta/baja-tension/4.jpg'

import mediaTensionOne from '../../../assets/images/servicios/servicios-planta/media-tension/1.jpg'
import mediaTensionTwo from '../../../assets/images/servicios/servicios-planta/media-tension/2.jpg'
import mediaTensionThree from '../../../assets/images/servicios/servicios-planta/media-tension/3.jpg'
import mediaTensionFour from '../../../assets/images/servicios/servicios-planta/media-tension/4.jpg'
import mediaTensionFive from '../../../assets/images/servicios/servicios-planta/media-tension/5.jpg'
import mediaTensionSix from '../../../assets/images/servicios/servicios-planta/media-tension/6.jpg'
import mediaTensionSeven from '../../../assets/images/servicios/servicios-planta/media-tension/7.jpg'
import mediaTensionEight from '../../../assets/images/servicios/servicios-planta/media-tension/8.jpg'

import corrienteContinuaOne from '../../../assets/images/servicios/servicios-planta/corriente-continua/1.jpg'

import motoresVerticalesOne from '../../../assets/images/servicios/servicios-planta/motores-verticales/1.jpg'
import motoresVerticalesTwo from '../../../assets/images/servicios/servicios-planta/motores-verticales/2.jpg'

import generadorOne from '../../../assets/images/servicios/servicios-planta/generadores/1.jpg'
import generadorTwo from '../../../assets/images/servicios/servicios-planta/generadores/2.jpg'
import generadorThree from '../../../assets/images/servicios/servicios-planta/generadores/3.jpg'
import generadorFour from '../../../assets/images/servicios/servicios-planta/generadores/4.jpg'
import generadorFive from '../../../assets/images/servicios/servicios-planta/generadores/5.png'

import bombaOne from '../../../assets/images/servicios/servicios-planta/bombas/1.jpg'
import bombaTwo from '../../../assets/images/servicios/servicios-planta/bombas/2.jpg'
import bombaThree from '../../../assets/images/servicios/servicios-planta/bombas/3.jpg'
import bombaFour from '../../../assets/images/servicios/servicios-planta/bombas/4.jpeg'
import bombaFive from '../../../assets/images/servicios/servicios-planta/bombas/5.jpg'

import transformadorOne from '../../../assets/images/servicios/servicios-planta/transformadores/1.jpg'
import transformadorTwo from '../../../assets/images/servicios/servicios-planta/transformadores/2.jpg'

import balanceoOne from '../../../assets/images/servicios/servicios-planta/balance-dinamico/1.jpg'
import balanceoTwo from '../../../assets/images/servicios/servicios-planta/balance-dinamico/2.jpg'
import balanceoThree from '../../../assets/images/servicios/servicios-planta/balance-dinamico/3.jpg'
import balanceoFour from '../../../assets/images/servicios/servicios-planta/balance-dinamico/4.jpg'

import propulsorOne from '../../../assets/images/servicios/servicios-planta/ventiladores/1.png'

import mecanizadoOne from '../../../assets/images/servicios/servicios-planta/mecanizado/1.png'
import mecanizadoTwo from '../../../assets/images/servicios/servicios-planta/mecanizado/2.jpg'
import mecanizadoThree from '../../../assets/images/servicios/servicios-planta/mecanizado/3.jpg'
import mecanizadoFour from '../../../assets/images/servicios/servicios-planta/mecanizado/4.jpg'
import mecanizadoFive from '../../../assets/images/servicios/servicios-planta/mecanizado/5.jpg'
import mecanizadoSix from '../../../assets/images/servicios/servicios-planta/mecanizado/6.png'
import mecanizadoSeven from '../../../assets/images/servicios/servicios-planta/mecanizado/7.jpg'

// Servicio de SITIO
import desmontajeMontaje from '../../../assets/images/servicios/servicios-sitio/desmontaje-montaje.jpg'
import comisionamiento from '../../../assets/images/servicios/servicios-sitio/Comisionamiento.jpg'
import ensayosTermografia from '../../../assets/images/servicios/servicios-sitio/Ensayos-de-termografia.jpg'
import analisisVibracion from '../../../assets/images/servicios/servicios-sitio/analisis-de-vibracion.jpg'
import boroscopio from '../../../assets/images/servicios/servicios-sitio/inspeccion-boroscopio.jpg'

import desmontajeOne from '../../../assets/images/servicios/servicios-sitio/desmontaje/1.jpeg'
import desmontajeTwo from '../../../assets/images/servicios/servicios-sitio/desmontaje/2.jpg'
import desmontajeThree from '../../../assets/images/servicios/servicios-sitio/desmontaje/3.jpeg'

import precomisionamientoOne from '../../../assets/images/servicios/servicios-sitio/precomisionamiento/1.jpg'
import precomisionamientoTwo from '../../../assets/images/servicios/servicios-sitio/precomisionamiento/2.jpeg'
import precomisionamientoThree from '../../../assets/images/servicios/servicios-sitio/precomisionamiento/3.png'
import precomisionamientoFour from '../../../assets/images/servicios/servicios-sitio/precomisionamiento/4.jpg'
import precomisionamientoFive from '../../../assets/images/servicios/servicios-sitio/precomisionamiento/5.jpg'

import monitoreoOne from '../../../assets/images/servicios/servicios-sitio/monitoreo-en-linea/1.jpg'
import monitoreoTwo from '../../../assets/images/servicios/servicios-sitio/monitoreo-en-linea/2.jpeg'

import termografiaOne from '../../../assets/images/servicios/servicios-sitio/termografia/1.jpg'

import alineacionOne from '../../../assets/images/servicios/servicios-sitio/alineacion-laser/1.jpg'
import alineacionTwo from '../../../assets/images/servicios/servicios-sitio/alineacion-laser/2.jpg'

import rodamientosOne from '../../../assets/images/servicios/servicios-sitio/rodamientos/1.jpg'
import rodamientosTwo from '../../../assets/images/servicios/servicios-sitio/rodamientos/2.jpg'

// Servicios autorizados
import asesoramiento from '../../../assets/images/servicios/servicios-autorizados/asesoriamiento.jpg'
import asistenciaTecnica from '../../../assets/images/servicios/servicios-autorizados/asistencia-tecnica.png'
import capacitaciones from '../../../assets/images/servicios/servicios-autorizados/capacitaciones.jpg'

// Repuestos - Componentes de
import ventiladores from '../../../assets/images/servicios/repuestos-componentes/ventiladores.jpg'
import anillos from '../../../assets/images/servicios/repuestos-componentes/anillos-lubricacion.jpg'
import reservorio from '../../../assets/images/servicios/repuestos-componentes/RESERVORIS.jpg'
import rodamientos from '../../../assets/images/servicios/repuestos-componentes/RODAMIENTOS.jpg'

import chumaceraOne from '../../../assets/images/servicios/repuestos-componentes/chumaceras/1.jpg'
import chumaceraTwo from '../../../assets/images/servicios/repuestos-componentes/chumaceras/2.jpg'
import chumaceraThree from '../../../assets/images/servicios/repuestos-componentes/chumaceras/3.jpg'

// Proyectos
import cincoMoteores from '../../../assets/images/servicios/proyectos/intervencion-5-motores.jpg'
import usaquen from '../../../assets/images/servicios/proyectos/ph-usaquen.jpg'

import usaquenOne from '../../../assets/images/servicios/proyectos/intervencion-pch-usaquen/1.jpg'
import usaquenTwo from '../../../assets/images/servicios/proyectos/intervencion-pch-usaquen/2.jpg'
import usaquenThree from '../../../assets/images/servicios/proyectos/intervencion-pch-usaquen/3.jpg'
import usaquenFour from '../../../assets/images/servicios/proyectos/intervencion-pch-usaquen/4.jpg'

import usaquenFinalOne from '../../../assets/images/servicios/proyectos/finales-pch-usaquen/1.jpg'
import usaquenFinalTwo from '../../../assets/images/servicios/proyectos/finales-pch-usaquen/2.jpg'
import usaquenFinalThree from '../../../assets/images/servicios/proyectos/finales-pch-usaquen/3.jpg'
import usaquenFinalFour from '../../../assets/images/servicios/proyectos/finales-pch-usaquen/4.jpg'
import usaquenFinalFive from '../../../assets/images/servicios/proyectos/finales-pch-usaquen/5.jpg'

import motoresOne from '../../../assets/images/servicios/proyectos/motores/1.jpg'
import motoresTwo from '../../../assets/images/servicios/proyectos/motores/2.jpg'
import motoresThree from '../../../assets/images/servicios/proyectos/motores/3.jpg'

import qatarOne from '../../../assets/images/servicios/proyectos/qatar/1.jpg'
import qatarTwo from '../../../assets/images/servicios/proyectos/qatar/2.jpg'
import qatarThree from '../../../assets/images/servicios/proyectos/qatar/3.jpg'
import qatarFour from '../../../assets/images/servicios/proyectos/qatar/4.jpg'
import qatarFive from '../../../assets/images/servicios/proyectos/qatar/5.jpg'
import qatarSix from '../../../assets/images/servicios/proyectos/qatar/6.jpg'
interface IServiceInfo {
  headline?: { es: string; en: string } | string | any
  description?: { es: string; en: string } | string | any
  img: string
  images?: string[]
  text?: { es: string; en: string } | string | any
}
export interface IService {
  id: number
  category?: { es: string; en: string } | string | any
  paragraph?: { es: string; en: string } | string | any
  info?: IServiceInfo[]
}

type button = {
  es: string | any
  en: string | any
}

export const buttonText: button | any = {
  es: 'QUIERO SABER MÁS',
  en: 'I WANT TO KNOW MORE'
}

export const servicesData: IService[] = [
  {
    id: 1,
    category: {
      es: 'SERVICIOS EN PLANTA',
      en: 'INDUSTRIAL SERVICES'
    },
    info: [
      {
        headline: {
          es: 'SERVICIOS EN PLANTA',
          en: 'INDUSTRIAL SERVICES'
        },
        description: {
          es: 'REPARACIÓN, BOBINADO Y MANTENIMIENTO PREVENTIVO DE MOTORES DE BAJA TENSIÓN',
          en: 'REPAIR, WINDING AND PREVENTIVE MAINTENANCE OF LOW-VOLTAGE MOTORS'
        },
        img: bajaTension,
        images: [
          bajaTension,
          bajaTensionOne,
          bajaTensionTwo,
          bajaTensionThree,
          bajaTensionFour
        ],
        text: {
          es: 'Nuestro alcance contempla rebobinado, mecanizados, suministro de repuestos originales, pruebas eléctricas y entrega de informes detallados.',
          en: 'Our scope includes winding, mechanical, original spare parts supply, electrical tests and detailed reports delivery.'
        }
      },
      {
        headline: {
          es: 'SERVICIOS EN PLANTA',
          en: 'INDUSTRIAL SERVICES'
        },
        description: {
          es: 'REPARACIÓN, BOBINADO Y MANTENIMIENTO PREVENTIVO DE MOTORES DE MEDIA TENSIÓN',
          en: 'REPAIR, WINDING AND PREVENTIVE MAINTENANCE OF MEDIUM-VOLTAGE MOTORS'
        },
        img: bobinadoMantenimiento,
        images: [
          bobinadoMantenimiento,
          mediaTensionOne,
          mediaTensionTwo,
          mediaTensionThree,
          mediaTensionFour,
          mediaTensionFive,
          mediaTensionSix,
          mediaTensionSeven,
          mediaTensionEight
        ],
        text: {
          es: 'Contamos con la infraestructura para el óptimo mantenimiento de motores hasta los 10.000 HP y personal calificado para la ejecución de actividades de mantenimiento preventivo y correctivo. Tenemos las mejores alianzas internacionales para la fabricación de bobinas especiales.',
          en: 'We have the infrastructure to the optimal maintenance of motors up to 10,000 HP and qualified personnel for the execution of preventive and corrective maintenance activities. We have the best international alliances for the manufacture of special winding.'
        }
      },
      {
        headline: {
          es: 'SERVICIOS EN PLANTA',
          en: 'INDUSTRIAL SERVICES'
        },
        description: {
          es: 'REPARACIÓN, BOBINADO Y MANTENIMIENTO PREVENTIVO DE MOTORES DE CORRIENTE CONTINUA',
          en: 'REPAIR, WINDING AND PREVENTIVE MAINTENANCE OF CONTINUOUS CURRENT MOTORS'
        },
        img: corrienteContinuaOne,
        images: [corrienteContinuaOne],
        text: {
          es: 'Rebobinados de armaduras, campos, bobinas auxiliares, fabricación de colectores, suministro de escobillas y portaescobillas.',
          en: 'Winding of armor, fields, auxiliary coils, coil collectors supply and portaescobillas.'
        }
      },
      {
        headline: {
          es: 'SERVICIOS EN PLANTA',
          en: 'INDUSTRIAL SERVICES'
        },
        description: {
          es: 'REPARACIÓN, BOBINADO Y MANTENIMIENTO PREVENTIVO DE MOTORES VERTICALES',
          en: 'REPAIR, WINDING AND PREVENTIVE MAINTENANCE OF VERTICAL MOTORS'
        },
        img: motorVertical,
        images: [motorVertical, motoresVerticalesOne, motoresVerticalesTwo],
        text: {
          es: 'Contamos con la infraestructura para el óptimo mantenimiento de generadores hasta 25 MVA.',
          en: 'We have the infrastructure to the optimal maintenance of generators up to 25 MVA.'
        }
      },
      {
        headline: {
          es: 'SERVICIOS EN PLANTA',
          en: 'INDUSTRIAL SERVICES'
        },
        description: {
          es: 'REPARACIÓN Y MANTENIMIENTO PREVENTIVO DE GENERADORES',
          en: 'REPAIR AND PREVENTIVE MAINTENANCE OF GENERATORS'
        },
        img: generadores,
        images: [
          generadores,
          generadorOne,
          generadorTwo,
          generadorThree,
          generadorFour,
          generadorFive
        ],
        text: {
          es: 'Contamos con la infraestructura para el óptimo mantenimiento de generadores hasta 25 MVA.',
          en: 'We have the infrastructure to the optimal maintenance of generators up to 25 MVA.'
        }
      },
      {
        headline: {
          es: 'SERVICIOS EN PLANTA',
          en: 'INDUSTRIAL SERVICES'
        },
        description: {
          es: 'REPARACIÓN DE BOMBAS SUMERGIBLES Y DE SUPERFICIE',
          en: 'REPAIR OF SUBMERGIBLE AND SURFACE TURBINES'
        },
        img: bombaOne,
        images: [
          bombasSumergibles,
          bombaOne,
          bombaTwo,
          bombaThree,
          bombaFour,
          bombaFive
        ],
        text: {
          es: 'Verticales, sumergibles, electrobombas. Realizamos suministro y cambio de sellos mecánicos, cambio de rodamientos, mantenimiento mecánico.',
          en: 'Vertical, submergible, electroturbines. We supply and change mechanical seals, change bearings, mechanical maintenance.'
        }
      },
      {
        headline: {
          es: 'SERVICIOS EN PLANTA',
          en: 'INDUSTRIAL SERVICES'
        },
        description: {
          es: 'MANTENIMIENTO PREVENTIVO Y REPARACIÓN DE TRANSFORMADORES',
          en: 'PREVENTIVE AND REPAIR OF TRANSFORMERS'
        },
        img: transformadorTwo,
        images: [transformador, transformadorOne, transformadorTwo],
        text: {
          es: 'Transformadores secos y en aceite.',
          en: 'Cold and oil transformers.'
        }
      },
      {
        headline: {
          es: 'SERVICIOS EN PLANTA',
          en: 'INDUSTRIAL SERVICES'
        },
        description: {
          es: 'BALANCEO DINÁMICO Y ESTÁTICO EN BANCO COMPUTARIZADO',
          en: 'DYNAMIC AND STATIC BALANCING IN COMPUTERIZED BANK'
        },
        img: balanceoDinamico,
        images: [
          balanceoDinamico,
          balanceoOne,
          balanceoTwo,
          balanceoThree,
          balanceoFour
        ],
        text: {
          es: 'La capacidad de nuestras maquinas balanceadoras alcanzan hasta las 12 toneladas.',
          en: 'Our balancing machines reach up to 12 tons.'
        }
      },
      {
        headline: {
          es: 'SERVICIOS EN PLANTA',
          en: 'INDUSTRIAL SERVICES'
        },
        description: {
          es: 'FABRICACIÓN DE IMPULSORES Y VENTILADORES',
          en: 'FABRICATION OF IMPULSORS AND VENTILATORS'
        },
        img: impulsoresVentiladores,
        images: [impulsoresVentiladores],
        text: {
          es: 'En aluminio o en fundiciones. Centrífugos y axiales.',
          en: 'Aluminum or in castings. Centrifuges and axial.'
        }
      },
      {
        headline: {
          es: 'SERVICIOS EN PLANTA',
          en: 'INDUSTRIAL SERVICES'
        },
        description: {
          es: 'FABRICACIÓN Y REPARACIÓN DE PROPULSORES Y TURBINAS',
          en: 'FABRICATION AND REPAIR OF PROPULSORS AND TURBINES'
        },
        img: impulsoresVentiladores,
        images: [impulsoresVentiladores, propulsorOne],
        text: {
          es: 'En hierro, acero inoxidable y bronce.',
          en: 'Iron, stainless steel and bronze.'
        }
      },
      {
        headline: {
          es: 'SERVICIOS EN PLANTA',
          en: 'INDUSTRIAL SERVICES'
        },
        description: {
          es: 'METALIZADO, MECANIZADO Y RECONSTRUCCIÓN DE EJES',
          en: 'METALIZED, MECHANIZED AND RECONSTRUCTION OF AXES'
        },
        img: mecanizadoTwo,
        images: [
          mecanizadoSix,
          mecanizadoOne,
          mecanizadoTwo,
          mecanizadoThree,
          mecanizadoFour,
          mecanizadoFive,
          mecanizadoSeven
        ],
        text: {
          es: '',
          en: ''
        }
      },
      {
        headline: {
          es: 'SERVICIOS EN PLANTA',
          en: 'INDUSTRIAL SERVICES'
        },
        description: {
          es: 'RECONSTRUCCIÓN DE CHUMACERAS EN BABBITT Y EN BRONCE, ANILLOS DE LUBRICACIÓN, VISORES DE NIVEL Y SELLOS',
          en: 'RECONSTRUCTION OF CHUMACERAS IN BABBITT AND IN BRONZE, BELT LUBRICATION ANGLES, LEVEL VISORS AND SEALS'
        },
        img: motorVertical,
        images: [motorVertical],
        text: {
          es: '',
          en: ''
        }
      }
    ]
  },
  {
    id: 2,
    category: {
      es: 'SERVICIOS EN SITIO',
      en: 'SITE SERVICES'
    },
    info: [
      {
        headline: {
          es: 'SERVICIOS EN SITIO',
          en: 'SITE SERVICE'
        },
        description: {
          es: 'DESMONTAJE Y MONTAJE DE EQUIPOS EN CAMPO',
          en: 'UNLOAD AND LOAD OF EQUIPMENTS IN FIELD'
        },
        img: desmontajeMontaje,
        images: [
          desmontajeMontaje,
          desmontajeOne,
          desmontajeTwo,
          desmontajeThree
        ]
      },
      {
        headline: {
          es: 'SERVICIOS EN SITIO',
          en: 'SITE SERVICE'
        },
        description: {
          es: 'PRECOMISIONAMIENTO, COMISIONAMIENTO Y PUESTA EN SERVICIO',
          en: 'PRE-COMMISSIONING, COMMISSIONING AND SERVICE'
        },
        img: comisionamiento,
        images: [
          comisionamiento,
          precomisionamientoOne,
          precomisionamientoTwo,
          precomisionamientoThree,
          precomisionamientoFour,
          precomisionamientoFive
        ]
      },
      {
        headline: {
          es: 'SERVICIOS EN SITIO',
          en: 'SITE SERVICE'
        },
        description: {
          es: 'PRUEBAS DE MONITOREO EN LÍNEA',
          en: 'ONLINE MONITORING TEST'
        },
        img: monitoreoOne,
        images: [monitoreoOne, monitoreoTwo]
      },
      {
        headline: {
          es: 'SERVICIOS EN SITIO',
          en: 'SITE SERVICE'
        },
        description: {
          es: 'ENSAYOS DE TERMOGRAFÍA',
          en: 'TEMPERATURE TESTING'
        },
        img: ensayosTermografia,
        images: [ensayosTermografia, termografiaOne]
      },
      {
        headline: {
          es: 'SERVICIOS EN SITIO',
          en: 'SITE SERVICE'
        },
        description: {
          es: 'ALINEACIÓN ÓPTICA LÁSER',
          en: 'OPTICAL ALIGNMENT LASER'
        },
        img: alineacionOne,
        images: [alineacionOne, alineacionTwo]
      },
      {
        headline: {
          es: 'SERVICIOS EN SITIO',
          en: 'SITE SERVICE'
        },
        description: {
          es: 'ANÁLISIS DE VIBRACIÓN',
          en: 'VIBRATION ANALYSIS'
        },
        img: analisisVibracion,
        images: [analisisVibracion]
      },
      {
        headline: {
          es: 'SERVICIOS EN SITIO',
          en: 'SITE SERVICE'
        },
        description: {
          es: 'INSPECCIÓN BOROSCOPIO',
          en: 'BOROSCOPIC INSPECTION'
        },
        img: boroscopio,
        images: [boroscopio]
      },
      {
        headline: {
          es: 'SERVICIOS EN SITIO',
          en: 'SITE SERVICE'
        },
        description: {
          es: 'CAMBIO DE RODAMIENTOS Y ELEMENTOS RODANTES',
          en: 'CHANGE OF RODS AND RODENTS'
        },
        img: rodamientosOne,
        images: [rodamientosOne, rodamientosTwo]
      }
    ]
  },
  {
    id: 3,
    category: {
      es: 'SERVICIOS AUTORIZADOS, ASESORÍA TÉCNICA Y CAPACITACIONES',
      en: 'AUTH SERVICES'
    },
    info: [
      {
        headline: {
          es: 'SERVICIOS AUTORIZADOS, ASESORÍA TÉCNICA Y CAPACITACIONES',
          en: 'SERVICES'
        },
        description: {
          es: 'ASESORAMIENTO Y ACOMPAÑAMIENTO EN LA ADQUISICIÓN DE MOTORES Y EQUIPOS ELÉCTRICOS ESPECIALIZADOS ',
          en: 'ADVICE AND ASSISTANCE IN THE ACQUISITION OF ELECTRICAL ENGINEERING SPECIALIZED EQUIPMENTS'
        },
        img: asesoramiento,
        images: [ventiladores]
      },
      {
        headline: {
          es: 'SERVICIOS AUTORIZADOS, ASESORÍA TÉCNICA Y CAPACITACIONES',
          en: 'SERVICES'
        },
        description: {
          es: 'ASISTENCIA TÉCNICA EN EL MANTENIMIENTO DE ACUERDO A SUS NECESIDADES INDUSTRIALES ESPECÍFICAS',
          en: 'TECHNICAL ASSISTANCE IN THE MAINTENANCE ACCORDING TO ITS INDUSTRIAL SPECIFIC NEEDS'
        },
        img: asistenciaTecnica,
        images: [ventiladores]
      },
      {
        headline: {
          es: 'SERVICIOS AUTORIZADOS, ASESORÍA TÉCNICA Y CAPACITACIONES',
          en: 'SERVICES'
        },
        description: {
          es: 'CAPACITACIONES ONLINE Y EN SITIO EN EL ÁREA DEL MANTENIMIENTO INDUSTRIAL (WEBINAR, SEMINARIOS, TALLERES)',
          en: 'ONLINE AND SITE TRAINING IN THE INDUSTRIAL MAINTENANCE AREA (WEBINAR, SEMINARS, WORKSHOPS)'
        },
        img: capacitaciones,
        images: [ventiladores]
      }
    ]
  },
  {
    id: 4,
    category: {
      es: 'SUMINISTRO DE REPUESTOS Y COMPONENTES',
      en: 'COMPONENTS'
    },
    paragraph: {
      es: 'Suministro de componentes y repuestos eléctricos y mecánicos (chumaceras, cajas borneras, condensadores, aisladores, sensores de temperatura (RTDs), visores de aceite, anillos de lubricación y sellos de laberinto).',
      en: 'Supply of electrical and mechanical components (chumaceras, boxes, condensators, insulation, sensors, oil visors, lubrication rings and maze seals).'
    },
    info: [
      {
        headline: {
          es: 'SUMINISTRO DE REPUESTOS Y COMPONENTES',
          en: 'COMPONENTS'
        },
        description: {
          es: 'SUMINISTRO DE IMPULSORES Y VENTILADORES ',
          en: 'SUPPLY OF PUMP AND VENTILATORS'
        },
        img: ventiladores,
        images: [ventiladores]
      },
      {
        headline: {
          es: 'SUMINISTRO DE REPUESTOS Y COMPONENTES',
          en: 'COMPONENTS'
        },
        description: {
          es: 'ELIXIR NOVA',
          en: 'ELIXIR NOVA'
        },
        img: serviceImage,
        images: [serviceImage]
      },
      {
        headline: {
          es: 'SUMINISTRO DE REPUESTOS Y COMPONENTES',
          en: 'COMPONENTS'
        },
        description: {
          es: 'RODAMIENTOS',
          en: 'RODS'
        },
        img: rodamientos,
        images: [rodamientos]
      },
      {
        headline: {
          es: 'SUMINISTRO DE REPUESTOS Y COMPONENTES',
          en: 'COMPONENTS'
        },
        description: {
          es: 'RESERVORIOS',
          en: 'RESERVOIRS'
        },
        img: reservorio,
        images: [reservorio]
      },
      {
        headline: {
          es: 'SUMINISTRO DE REPUESTOS Y COMPONENTES',
          en: 'COMPONENTS'
        },
        description: {
          es: 'ANILLOS DE LUBRICACIÓN',
          en: 'LUBRICATION BELTS'
        },
        img: anillos,
        images: [anillos]
      },
      {
        headline: {
          es: 'SUMINISTRO DE REPUESTOS Y COMPONENTES',
          en: 'COMPONENTS'
        },
        description: {
          es: 'CHUMACERAS',
          en: 'CHUMACERAS'
        },
        img: chumaceraOne,
        images: [chumaceraOne, chumaceraTwo, chumaceraThree]
      }
    ]
  },
  {
    id: 5,
    category: {
      es: 'PROYECTOS',
      en: 'PROYECTS'
    },
    paragraph: {
      es: 'Somos una compañía de mejoramiento continuo y orientada a soluciones, nos enfocamos en la confiabilidad operativa de nuestros clientes para asegurar su producción. Contactanos para mas información',
      en: 'We are a continuous improvement company oriented to solutions, we focus on the reliability of our clients to ensure their production. Contact us for more information'
    },
    info: [
      {
        headline: {
          es: 'PROYECTOS',
          en: 'PROYECTS'
        },
        description: {
          es: 'INTERVENCIÓN DE 5 MOTORES DE MEDIA TENSIÓN MARCA WOLONG EN MENOS DE 20 DÍAS ',
          en: '5 MEDIUM-TURBINE MOTORS WOLONG IN LESS THAN 20 DAYS'
        },
        img: cincoMoteores,
        images: [cincoMoteores, motoresOne, motoresTwo, motoresThree]
      },
      {
        headline: {
          es: 'PROYECTOS',
          en: 'PROYECTS'
        },
        description: {
          es: 'INTERVENCIÓN PCH USAQUÉN',
          en: 'INTERVENTION PCH USAQUÉN'
        },
        img: usaquen,
        images: [
          usaquen,
          usaquenOne,
          usaquenTwo,
          usaquenThree,
          usaquenFour,
          usaquenFinalOne,
          usaquenFinalTwo,
          usaquenFinalThree,
          usaquenFinalFour,
          usaquenFinalFive
        ]
      },
      {
        headline: {
          es: 'PROYECTOS',
          en: 'PROYECTS'
        },
        description: {
          es: 'PROYECTO QATAR',
          en: 'QATAR PROJECT'
        },
        img: qatarOne,
        images: [
          qatarThree,
          qatarTwo,
          qatarThree,
          qatarFour,
          qatarFive,
          qatarSix
        ]
      }
    ]
  }
]
