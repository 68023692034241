import React, { useContext } from 'react'
import GeneralContext from '../../general/generalContext/GeneralContext'
import { textNormative, cardNormative } from '../data'

const Normative = () => {
  const { language } = useContext(GeneralContext)
  return (
    <section className="bg-white w-full">
      <div className="container m-auto flex flex-col justify-center items-center w-10/12 py-[60px]">
        <div className="w-[80%] flex flex-col justify-center items-center space-y-6">
          <h2 className="font-town text-[23px] md:text-[30px] font-bold tracking-[0.15em] leading-[36px]">
            {textNormative.title[language]}
          </h2>
          <p className="font-segoe font-thin tracking-[0.03em] md:tracking-[0.04em] text-[14px] md:text-[16px] leading-[29px] text-center">
            {textNormative.description[language]}
          </p>
        </div>
        <div className="w-full py-[60px] flex justify-center items-center gap-10 flex-wrap ">
          {cardNormative.map((card: any) => (
            <div
              className="w-[350px] h-[115px] flex gap-x-8 items-center rounded-lg shadow-md bg-white hover:scale-105 transition duration-300 "
              key={card.id}
            >
              <div className="w-[20%] ml-6">
                <img
                  className="rounded-l-lg w-full object-cover"
                  src={card.img}
                  alt={card.description[language]}
                />
              </div>
              <p className=" w-[50%] font-segoe font-extralight text-[14px] lg:text-[16px] tracking-[0.04em] leading-[29px]">
                {card.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Normative
