import React from 'react'
import HeroSection from './components/HeroSection'
import Intro from './components/Intro'
import Normative from './components/Normative'
import { sectionOne, sectionThree, sectionTwo } from './data'

const Quality = () => {
  return (
    <div>
      <Intro />
      <HeroSection {...sectionOne} />
      <HeroSection {...sectionTwo} />
      <HeroSection {...sectionThree} />
      <Normative />
    </div>
  )
}

export default Quality
