export interface IFooterData {
  whyUs: { es: string; en: string }
  quality: { es: string; en: string }
  security: { es: string; en: string }
  prestige: { es: string; en: string }
  innovation: { es: string; en: string }
}

export const footerText: any = {
  whyUs: {
    es: '¿Por qué nosotros?',
    en: 'Why us?'
  },
  quality: {
    es: 'Calidad',
    en: 'Quality'
  },
  security: {
    es: 'Seguridad',
    en: 'Security'
  },
  prestige: {
    es: 'Prestigio',
    en: 'Prestige'
  },
  innovation: {
    es: 'Innovación',
    en: 'Innovation'
  },
  ourServices: {
    es: 'Nuestros Servicios',
    en: 'Our Services'
  },
  workshop: {
    es: 'Servicios en planta',
    en: 'Workshop services'
  },

  serviceOnSite: {
    es: 'Servicios en sitio',
    en: 'On-Site Services'
  },
  technicalSupport: {
    es: 'Asesoría Técnica y Capacitaciones',
    en: 'Technical Support and Training'
  },
  proyects: {
    es: 'Proyectos',
    en: 'Proyects'
  },
  brands: {
    es: 'Fabricantes',
    en: 'Brands'
  },
  nameBogota: { es: 'Bogotá', en: 'Bogota' },
  bogotaCity: {
    es: 'Calle 24 # 24 – 17',
    en: 'Calle 24 # 24 – 17'
  },
  neighborhoodBogota: {
    es: 'Dirección Samper Mendoza',
    en: 'Neighborhood Samper Mendoza'
  },
  pbxBogota: '+57 (1) 702 4589; +57 (1) 702 7791',
  cellphoneBogota: {
    es: 'Cel.: +57 301 787 9332',
    en: 'Phone.: +57 301 787 9332'
  },
  nameCartagena: 'Cartagena',
  cartagenaCity: {
    es: 'Diagonal  21 A # 55 – 64',
    en: 'Diagonal 21 A # 55 – 64'
  },
  neighborhoodCartagena: {
    es: ' Barrio el Bosque',
    en: 'Barrio el Bosque'
  },
  pbx: 'PBX: +57 (5) 693 3378',
  cellphoneCartagena: {
    es: 'Cel.: +57 317 894 4281',
    en: 'Phone.: +57 317 894 4281'
  },
  workWithUs: {
    es: 'Trabaja con nosotros',
    en: 'Work with us'
  },
  downloadBrochure: {
    es: 'Descargar brochure',
    en: 'Download brochure'
  },
  politics: {
    es: 'Políticas y condiciones generales',
    en: 'General Policies and Conditions'
  },
  garanties: {
    es: 'Garantías del servicio',
    en: 'Service guarantees'
  }
}
