import imagePost from '../../../assets/images/newPost.png'

export type posts = {
  id: number
  date: { es: string; en: string }
  title: { es: string; en: string }
  image: string
  content: string
}

export const cover: posts[] = [
  {
    id: 1,
    date: {
      es: 'Enero 12, 2021',
      en: 'January 12, 2021'
    },
    title: {
      es: 'Título del artículo',
      en: 'Article title'
    },
    image: imagePost,
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis consequat turpis donec tincidunt nulla varius lectus. Quis commodo eros arcu pulvinar amet. Consequat sit bibendum risus augue mi integer massa volutpat at. Blandit elementum nisi diam fermentum ut laoreet adipiscing cursus quis. Sem congue etiam fermentum, rhoncus tellus. Suspendisse scelerisque penatibus ultrices posuere id sed. Egestas sagittis adipiscing viverra vivamus mi. Mauris, mollis at adipiscing non. Est, pulvinar ac ullamcorper vitae. Scelerisque aenean id a commodo sed dui urna. Dui, sapien fermentum lacinia sit. Nunc, nunc, eget volutpat neque amet, porttitor scelerisque. Suspendisse fermentum tellus lacus, proin.Ultrices duis purus nulla maecenas quis integer. Phasellus eu, amet ornare fermentum. Egestas quis arcu viverra tellus elementum commodo elementum. Orci turpis vitae nibh placerat purus. Purus phasellus molestie quisque viverra faucibus imperdiet. Neque urna non enim nisi mi porttitor amet, adipiscing leo. Nunc dis ut in donec. Amet nibh sollicitudin sit arcu turpis ut nulla facilisis. Integer eros proin adipiscing pretium, quam gravida. Integer ipsum felis vitae arcu sed ullamcorper dictumst sed ac. Enim leo nulla in dui purus orci integer nulla eu.'
  },
  {
    id: 2,
    date: {
      es: 'Enero 12, 2021',
      en: 'January 12, 2021'
    },
    title: {
      es: 'Título del artículo',
      en: 'Article title'
    },

    image: imagePost,
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis consequat turpis donec tincidunt nulla varius lectus. Quis commodo eros arcu pulvinar amet. Consequat sit bibendum risus augue mi integer massa volutpat at. Blandit elementum nisi diam fermentum ut laoreet adipiscing cursus quis. Sem congue etiam fermentum, rhoncus tellus. Suspendisse scelerisque penatibus ultrices posuere id sed. Egestas sagittis adipiscing viverra vivamus mi. Mauris, mollis at adipiscing non. Est, pulvinar ac ullamcorper vitae. Scelerisque aenean id a commodo sed dui urna. Dui, sapien fermentum lacinia sit. Nunc, nunc, eget volutpat neque amet, porttitor scelerisque. Suspendisse fermentum tellus lacus, proin.Ultrices duis purus nulla maecenas quis integer. Phasellus eu, amet ornare fermentum. Egestas quis arcu viverra tellus elementum commodo elementum. Orci turpis vitae nibh placerat purus. Purus phasellus molestie quisque viverra faucibus imperdiet. Neque urna non enim nisi mi porttitor amet, adipiscing leo. Nunc dis ut in donec. Amet nibh sollicitudin sit arcu turpis ut nulla facilisis. Integer eros proin adipiscing pretium, quam gravida. Integer ipsum felis vitae arcu sed ullamcorper dictumst sed ac. Enim leo nulla in dui purus orci integer nulla eu.'
  },
  {
    id: 3,
    date: {
      es: 'Enero 12, 2021',
      en: 'January 12, 2021'
    },
    title: {
      es: 'Título del artículo',
      en: 'Article title'
    },

    image: imagePost,
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis consequat turpis donec tincidunt nulla varius lectus. Quis commodo eros arcu pulvinar amet. Consequat sit bibendum risus augue mi integer massa volutpat at. Blandit elementum nisi diam fermentum ut laoreet adipiscing cursus quis. Sem congue etiam fermentum, rhoncus tellus. Suspendisse scelerisque penatibus ultrices posuere id sed. Egestas sagittis adipiscing viverra vivamus mi. Mauris, mollis at adipiscing non. Est, pulvinar ac ullamcorper vitae. Scelerisque aenean id a commodo sed dui urna. Dui, sapien fermentum lacinia sit. Nunc, nunc, eget volutpat neque amet, porttitor scelerisque. Suspendisse fermentum tellus lacus, proin.Ultrices duis purus nulla maecenas quis integer. Phasellus eu, amet ornare fermentum. Egestas quis arcu viverra tellus elementum commodo elementum. Orci turpis vitae nibh placerat purus. Purus phasellus molestie quisque viverra faucibus imperdiet. Neque urna non enim nisi mi porttitor amet, adipiscing leo. Nunc dis ut in donec. Amet nibh sollicitudin sit arcu turpis ut nulla facilisis. Integer eros proin adipiscing pretium, quam gravida. Integer ipsum felis vitae arcu sed ullamcorper dictumst sed ac. Enim leo nulla in dui purus orci integer nulla eu.'
  },
  {
    id: 4,
    date: {
      es: 'Enero 12, 2021',
      en: 'January 12, 2021'
    },
    title: {
      es: 'Título del artículo',
      en: 'Article title'
    },

    image: imagePost,
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis consequat turpis donec tincidunt nulla varius lectus. Quis commodo eros arcu pulvinar amet. Consequat sit bibendum risus augue mi integer massa volutpat at. Blandit elementum nisi diam fermentum ut laoreet adipiscing cursus quis. Sem congue etiam fermentum, rhoncus tellus. Suspendisse scelerisque penatibus ultrices posuere id sed. Egestas sagittis adipiscing viverra vivamus mi. Mauris, mollis at adipiscing non. Est, pulvinar ac ullamcorper vitae. Scelerisque aenean id a commodo sed dui urna. Dui, sapien fermentum lacinia sit. Nunc, nunc, eget volutpat neque amet, porttitor scelerisque. Suspendisse fermentum tellus lacus, proin.Ultrices duis purus nulla maecenas quis integer. Phasellus eu, amet ornare fermentum. Egestas quis arcu viverra tellus elementum commodo elementum. Orci turpis vitae nibh placerat purus. Purus phasellus molestie quisque viverra faucibus imperdiet. Neque urna non enim nisi mi porttitor amet, adipiscing leo. Nunc dis ut in donec. Amet nibh sollicitudin sit arcu turpis ut nulla facilisis. Integer eros proin adipiscing pretium, quam gravida. Integer ipsum felis vitae arcu sed ullamcorper dictumst sed ac. Enim leo nulla in dui purus orci integer nulla eu.'
  },
  {
    id: 5,
    date: {
      es: 'Enero 12, 2021',
      en: 'January 12, 2021'
    },
    title: {
      es: 'Título del artículo',
      en: 'Article title'
    },

    image: imagePost,
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis consequat turpis donec tincidunt nulla varius lectus. Quis commodo eros arcu pulvinar amet. Consequat sit bibendum risus augue mi integer massa volutpat at. Blandit elementum nisi diam fermentum ut laoreet adipiscing cursus quis. Sem congue etiam fermentum, rhoncus tellus. Suspendisse scelerisque penatibus ultrices posuere id sed. Egestas sagittis adipiscing viverra vivamus mi. Mauris, mollis at adipiscing non. Est, pulvinar ac ullamcorper vitae. Scelerisque aenean id a commodo sed dui urna. Dui, sapien fermentum lacinia sit. Nunc, nunc, eget volutpat neque amet, porttitor scelerisque. Suspendisse fermentum tellus lacus, proin.Ultrices duis purus nulla maecenas quis integer. Phasellus eu, amet ornare fermentum. Egestas quis arcu viverra tellus elementum commodo elementum. Orci turpis vitae nibh placerat purus. Purus phasellus molestie quisque viverra faucibus imperdiet. Neque urna non enim nisi mi porttitor amet, adipiscing leo. Nunc dis ut in donec. Amet nibh sollicitudin sit arcu turpis ut nulla facilisis. Integer eros proin adipiscing pretium, quam gravida. Integer ipsum felis vitae arcu sed ullamcorper dictumst sed ac. Enim leo nulla in dui purus orci integer nulla eu.'
  },
  {
    id: 6,
    date: {
      es: 'Enero 12, 2021',
      en: 'January 12, 2021'
    },
    title: {
      es: 'Título del artículo',
      en: 'Article title'
    },

    image: imagePost,
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis consequat turpis donec tincidunt nulla varius lectus. Quis commodo eros arcu pulvinar amet. Consequat sit bibendum risus augue mi integer massa volutpat at. Blandit elementum nisi diam fermentum ut laoreet adipiscing cursus quis. Sem congue etiam fermentum, rhoncus tellus. Suspendisse scelerisque penatibus ultrices posuere id sed. Egestas sagittis adipiscing viverra vivamus mi. Mauris, mollis at adipiscing non. Est, pulvinar ac ullamcorper vitae. Scelerisque aenean id a commodo sed dui urna. Dui, sapien fermentum lacinia sit. Nunc, nunc, eget volutpat neque amet, porttitor scelerisque. Suspendisse fermentum tellus lacus, proin.Ultrices duis purus nulla maecenas quis integer. Phasellus eu, amet ornare fermentum. Egestas quis arcu viverra tellus elementum commodo elementum. Orci turpis vitae nibh placerat purus. Purus phasellus molestie quisque viverra faucibus imperdiet. Neque urna non enim nisi mi porttitor amet, adipiscing leo. Nunc dis ut in donec. Amet nibh sollicitudin sit arcu turpis ut nulla facilisis. Integer eros proin adipiscing pretium, quam gravida. Integer ipsum felis vitae arcu sed ullamcorper dictumst sed ac. Enim leo nulla in dui purus orci integer nulla eu.'
  }
]
