export const menuData: any = {
  menu: {
    es: 'MENÚ',
    en: 'MENU'
  },
  whyUs: {
    es: '¿POR QUÉ NOSOTROS?',
    en: 'WHY US?'
  },
  home: {
    es: 'INICIO',
    en: 'HOME'
  },
  whatWeDo: {
    es: 'QUÉ HACEMOS',
    en: 'WHAT WE DO'
  },
  ourMotor: {
    es: 'NUESTRO MOTOR',
    en: 'OUR MOTOR'
  },
  posts: {
    es: 'PUBLICACIONES',
    en: 'POSTS'
  },
  knowLKS: {
    es: 'Conoce como LKS hace la diferencia y beneficia a las empresas',
    en: 'Know how LKS makes the difference and benefits the companies'
  },
  quality: {
    es: 'CALIDAD',
    en: 'QUALITY'
  },
  security: {
    es: 'SEGURIDAD',
    en: 'SECURITY'
  },
  prestige: {
    es: 'PRESTIGIO',
    en: 'PRESTIGE'
  },
  innovation: {
    es: 'INNOVACIÓN',
    en: 'INNOVATION'
  }
}
