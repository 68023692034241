import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import arrow from '../../../assets/arrow.svg'
import imagePost from '../../../assets/images/newPost.png'
import GeneralContext from '../../general/generalContext/GeneralContext'
import { useParams } from 'react-router'
import { cover } from '../data'

const NewsPublish: React.FC = () => {
  const { language } = useContext(GeneralContext)
  const history = useHistory()
  const params: { id: string } = useParams()
  const currentPost: any = cover.find((post) => String(post.id) === params.id)

  const { handleHeaderColor } = useContext(GeneralContext)

  useEffect(() => {
    handleHeaderColor('black')

    return () => {
      handleHeaderColor('white')
    }
  }, [])

  console.log(currentPost)

  return (
    <div className="w-[80%] max-w-[1440px] mt-40 lg:mt-60 flex flex-col m-auto space-y-5 2xl:container 2xl:m-auto 2xl:mt-60  ">
      <div
        onClick={() => history.push('/publicaciones')}
        className="w-10/12 xl:ml-10 flex gap-4 cursor-pointer"
      >
        <img
          src={arrow}
          alt="atras"
          className=" duration-300 hover:scale-125 transition-all "
        />
        <span className="font-segoe tracking-[0.15em] font-bold ">Volver</span>
      </div>
      <div className="w-[95%] m-auto flex flex-col lg:flex-row lg:gap-x-20 space-y-9 lg:space-y-0">
        <div className="space-y-3 lg:w-6/12">
          <img
            src={imagePost}
            className="w-full h-[150px] lg:h-[300px] lg:rounded-lg object-cover"
            alt=""
          />
          <div className=" flex flex-col space-y-2">
            <h3 className="text-[20px] font-bold font-segoe tracking-[0.15em] leading-[27px]">
              {currentPost?.title[language]}
            </h3>
            <span className="text-[16px] tracking-[0.15em] leading-[19px] font-normal">
              {currentPost?.date[language]}
            </span>
          </div>
        </div>
        <div className="w-full">
          <p className="tracking-[0.03em] leading-[23px] text-[15px] md:text-[16px] font-segoe md:tracking-[0.03em] font-light">
            {currentPost?.content}
          </p>
        </div>
      </div>
    </div>
  )
}

export default NewsPublish
