import React, { useContext } from 'react'
import Banner from '../../general/banner/Banner'
import servicesBanner from '../../../assets/images/servicesBanner.png'
import GeneralContext from '../../general/generalContext/GeneralContext'

const MenuService = ({
  setActiveCategory,
  categories,
  activeCategory
}: {
  categories: any
  setActiveCategory: (categories: any) => void
  activeCategory: any
}) => {
  const { language } = useContext(GeneralContext)

  return (
    <>
      <Banner
        bgImage={servicesBanner}
        title={language === 'es' ? 'Nuestros servicios' : 'Our services'}
      />
      <section className=" w-full bg-white ">
        <div className="container w-full m-auto h-[120px] gap-24 md:gap-24 flex px-6 justify-between items-center overflow-x-auto scrollbar scrollbar-thumb ">
          {categories.map((cat: any) => {
            return (
              <span
                key={cat.id}
                className={
                  ' flex justify-center items-center w-full min-w-[150px] h-full text-center text-[13px] tracking-[0.15em] font-town cursor-pointer hover:tab-selected-secondary  transition duration-400' +
                  (activeCategory.id === cat.id
                    ? ' tab-selected-secondary'
                    : '')
                }
                onClick={() => setActiveCategory(cat)}
              >
                {cat.category[language]}
              </span>
            )
          })}
        </div>
      </section>
      {/* <div className="w-11/12 md:w-[60%] m-auto font-segoe mt-[60px] text-center font-thin">
        <h3 className="text-[16px] font-normal letter-spacing-[0.04em] leading-[29px]">
          {activeCategory && activeCategory.paragraph[language]}
        </h3>
      </div> */}
    </>
  )
}

export default MenuService
