import { useState } from 'react'
import { testimonialsLks } from '../data'

const Testimonials = ({ language }: any) => {
  const [position, setPosition] = useState<any>(0)
  const length = testimonialsLks.testimonials.length

  const nextSlide = () => {
    setPosition(position === length - 1 ? 0 : position + 1)
  }

  const prevSlide = () => {
    setPosition(position === 0 ? length - 1 : position - 1)
  }

  if (
    !Array.isArray(testimonialsLks.testimonials) ||
    testimonialsLks.testimonials.length <= 0
  ) {
    return null
  }

  return (
    <div className=" py-[60px] mt-14 bg-white">
      <div className=" container m-auto f-full flex- flex-col space-y-6">
        <h2 className="text-[28px] lg:text-[30px] tracking-[0.15em] font-town font-bold grid place-items-center">
          {testimonialsLks.title[language]}
        </h2>
        <div className="relative h-max-[600px] flex flex-col justify-center items-center pt-5">
          {testimonialsLks.testimonials.map(
            (testimonial: any, index: number) => {
              return (
                <div
                  key={index}
                  className={
                    index === position
                      ? 'flex  justify-center items-center opacity-100 transition duration-300 scale-105'
                      : 'flex   justify-center items-center opacity-0 transition ease-linear'
                  }
                >
                  {index === position && (
                    <div className=" flex flex-col justify-center items-center space-y-10">
                      <img
                        className="w-[80px] h-[80px] md:w-[138px] md:h-[138px] object-contain"
                        src={testimonial.image}
                        alt={testimonial.name}
                      />
                      <div className="text-center flex flex-col gap-y-1">
                        <h4 className="text-[28px] lg:text-[30px] font-town tracking-[0.15em] leading-[36px]">
                          {testimonial.name}
                        </h4>
                        <span className="font-segoe font-thin uppercase text-[20px] lg:text-[22px] mt-3 tracking-[0.03em] leading-[31px]">
                          {testimonial.company}
                        </span>
                      </div>
                      <p className="font-segoe text-[14px] lg:text-[16px] font-extralight tracking-[0.04em] leading-[29px] text-center max-w-[600px] px-9">
                        {testimonial.text[language]}
                      </p>
                      <div className="w-full flex justify-center items-center gap-3 mt-8">
                        <button
                          onClick={prevSlide}
                          className="arrow-left"
                        ></button>
                        <button
                          onClick={nextSlide}
                          className="arrow-right"
                        ></button>
                      </div>
                    </div>
                  )}
                </div>
              )
            }
          )}
        </div>
      </div>
    </div>
  )
}

export default Testimonials
