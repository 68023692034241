import Banner from '../general/banner/Banner'
import InnovationBanner from '../../assets/images/innovation/innovationBanner.jpg'
import Commandments from '../../assets/images/innovation/commandments.jpg'
import Process from '../../assets/images/innovation/processInnovation.jpg'
import CarouselPosts from '../homepage/CarouselPosts'
import { textInnovation } from './data'
import GeneralContext from '../general/generalContext/GeneralContext'
import { useContext } from 'react'

type commandments = { es: string; en: string }[] | string[] | any

const Innovation = () => {
  const { language } = useContext(GeneralContext)
  return (
    <div>
      <Banner
        bgImage={InnovationBanner}
        title={textInnovation.title[language]}
      />
      <p className="w-[80%] lg:w-[70%] text-left font-segoe font-semiLight leading-6 lg:leading-7 tracking-[0.03em] lg:tracking-[0.04em] mx-auto max-w-[1300px] my-[40px] lg:my-[60px] text-[15px] lg:text-[16px]">
        {textInnovation.firstParagraph[language]}
        <br />
        <br />
        {textInnovation.secondParagraph[language]}
        <br />
        <br />
        {textInnovation.thirdParagraph[language]}
      </p>
      <div className=" bg-white w-full">
        <div className="w-full flex flex-col px-[30px] sm:px-[40px] lg:px-[100px] sm:flex-row sm:justify-center lg:mt-[86px] py-[60px] mx-auto max-w-[1500px]">
          <img
            src={Process}
            alt="Proceso de innovación"
            className="w-[300px] lg:w-[450px] object-contain self-center"
          />
          <div className="lg:mt-[90px] sm:pl-[30px] lg:pl-[77px]">
            <h2 className="font-bold text-[23px] lg:text-[30px] font-town tracking-[0.15em] leading-9 mt-[25px] sm:mt-0">
              {textInnovation.processTitle[language]}
            </h2>
            <p className="font-semiLight leading-7 tracking-[0.04em] mt-[25px] sm:mt-[20px] flex flex-col sm:pl-[5%]">
              {textInnovation.processText[language]}
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full px-[30px] sm:px-[40px] lg:px-[100px] py-[40px] lg:py-[60px]">
        <img
          src={Commandments}
          className="w-full min-h-[150px] object-cover max-h-[300px] max-w-[1500px] mx-auto"
          alt="Mandamientos"
        />
        <div className="max-w-[1500px] mx-auto">
          <h3 className="font-town font-bold text-[23px] lg:text-[28px] leading-7 lg:leading-8 tracking-[0.15em] lg:w-[500px] mt-[30px]">
            {textInnovation.titleMandatory[language]}
          </h3>
          <ul
            className="mt-[30px] font-segoe font-semiLight leading-8 tracking-[0.04em] pl-5 lg:grid lg:grid-cols-2 lg:gap-x-5"
            style={{ listStyle: 'disc' }}
          >
            {textInnovation.commandments.map(
              (item: commandments, index: number) => (
                <li key={index}>{item[language]}</li>
              )
            )}
          </ul>
        </div>
      </div>
      <div className="bg-white">
        <CarouselPosts title={'EL ÚLTIMO MOVIMIENTO INNOVANDO'} />
      </div>
    </div>
  )
}

export default Innovation
