import Check from './../../assets/images/ourCompany/check.svg'
import { items, agregateValueData } from './data'

const AggregateValue = ({ language }: any) => {
  return (
    <div className="px-[30px] lg:px-[20%] py-16 bg-white">
      <div className="max-w-[1500px] mx-auto">
        <h3 className="font-town font-bold text-[23px] lg:text-[30px] tracking-[0.15em] leading-7 lg:mb-[10px]">
          {agregateValueData.title[language]}
        </h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 sm:gap-x-10 gap-y-12 mt-8">
          {items.map((item: string, id: number) => (
            <div key={id} className="flex items-center">
              <img src={Check} alt="Check" className="mr-5" />
              <span className="font-segoe font-semiLight text-[16px] leading-6 tracking-[0.03em]">
                {item[language]}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default AggregateValue
