import InformativeModal from '../informativeModal/InformativeModal'

const Directory = ({ handleClose }: { handleClose: () => void }) => {
  return (
    <InformativeModal handleClose={() => handleClose()} type="directory">
      <h2 className="font-bold text-xl tracking-[0.15em]">DIRECTORIO</h2>
      <h3 className="text-sm tracking-[0..06em] mt-4">
        ASESORÍA TÉCNICA ESPECIALIZADA:
      </h3>
      <p className="tracking-[0.04em] leading-7 mt-[10px]">
        Gerencia Técnica FSE: <u>lks@lks.com.co</u>
      </p>
      <p className="tracking-[0.04em] leading-7 mt-[10px]">
        Ingeniero Juan Del Mar Yern: <u>juangarcia@lks.com.co</u>
      </p>
      <p className="tracking-[0.04em] leading-7 mt-[10px]">
        Ingeniero Juan Carlos Garcia: <u>carlos.garcia@lks.com.co</u>
      </p>
      <p className="tracking-[0.04em] leading-7 mt-[10px]">
        Dirección Planta Bogotá: <u>direccionplanta@lks.com.co</u>
      </p>
      <p className="tracking-[0.04em] leading-7 mt-[10px]">
        Dirección Planta Cartagena: <u>direccionplanta2@lks.com.co</u>
      </p>
      <h3 className="text-sm tracking-[0..06em] mt-8">
        EQUIPO ADMINISTRATIVO:
      </h3>
      <p className="tracking-[0.04em] leading-7 mt-[10px]">
        Notificaciones Legales, Contratos, Licitaciones y Proyectos: <u>subgerencia@lks.com.co</u>
      </p>
      <p className="tracking-[0.04em] leading-7 mt-[10px]">
        Información general, cotizaciones, ordenes de compra y notificaciones:
        Bogotá: <u>comercial@lks.com.co</u> Cartagena: <u>administracion@lks.com.co</u>
      </p>
      <p className="tracking-[0.04em] leading-7 mt-[10px]">
        Facturación electrónica: <u>facturacionelectronica@lks.com.co</u>
      </p>
      <p className="tracking-[0.04em] leading-7 mt-[10px]">
      Seguridad, Salud, Calidad y Medio Ambiente: <u> hseq@lks.com.co</u>
      </p>
      <p className="tracking-[0.04em] leading-7 mt-[10px]">
        Gerencia y asuntos administrativos: <u>gerencia@lks.com.co</u>
      </p>
      <p className="tracking-[0.04em] leading-7 mt-[10px] mb-9">
        Nuevas líneas de negocio y proyectos de innovación: <u>innovacion@lks.com.co</u>
      </p>
    </InformativeModal>
  )
}

export default Directory
