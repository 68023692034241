import PlantServices from './../../assets/images/homepage/servicesPlant.jpg'
import SiteServices from './../../assets/images/homepage/servicesSite.jpg'
import Diagnoses from './../../assets/images/homepage/diagnoses.jpg'

const homepageText = {
  homeBanner: {
    title: {
      es: 'Bienvenido a LKS',
      en: 'Welcome to LKS'
    },
    text: {
      es: 'LKS es una empresa con más de 50 años de experiencia que ha estado a la vanguardia en la prestación de servicios industriales para las empresas de producción de bienes y servicios. ',
      en: 'LKS is a company with more than 50 years of experience providing industrial services constructing motors.  '
    },
    button: {
      es: 'QUIERO SABER MÁS',
      en: 'I WANT TO KNOW MORE'
    }
  },

  informativeSection: {
    title: {
      es: 'Somos especialistas',
      en: 'We are specialists'
    },
    characteres: {
      es: [
        'Motores eléctricos y generadores de baja y media tensión',
        'Bombas sumergibles y de superficie',
        'Turbinas',
        'Turbos generadores',
        'Transformadores',
        'Soluciones industriales eléctricas y mecánicas para la producción y transformación de energía eléctrica.'
      ],
      en: [
        'Electric motors and generators of low and medium voltage',
        'Submersible and surface pumps',
        'Turbines',
        'Generators of turbines',
        'Transformers',
        'Industrial electric and mechanical solutions for the production and transformation of electrical energy.'
      ]
    }
  },
  carouselServices: {
    services: [
      {
        img: Diagnoses,
        title: {
          es: 'Diagnóstico',
          en: 'Diagnosis'
        },
        text: 'Mantenimiento predictivo. Utilizamos alta tecnología para la realización de diagnósticos que evidencien el estado actual y preciso de sus equipos.'
      },
      {
        img: SiteServices,
        title: {
          es: 'Servicios de sitio',
          en: 'Site services'
        },
        text: 'Atención de urgencias 24 horas 7 días a la semana. Cobertura y asesoría técnica especializada nacional e internacionalmente. Proyectos de servicio integral de mantenimiento.'
      },
      {
        img: PlantServices,
        title: {
          es: 'Servicios de planta',
          en: 'Plant services'
        },
        text: 'Conoce las actividades de mantenimiento preventivo y correctivo de maquinaria eléctrica rotativa que ejecutamos en nuestras instalaciones. '
      }
    ]
  },
  certificateCompanies: {
    title: {
      es: 'Empresas que nos certifican',
      en: 'Companies that certify us'
    }
  },
  informativeSectionOurMotor: {
    title: {
      es: 'Nuestro Motor',
      en: 'Our Motor'
    },
    description: {
      es: 'Descubre la motivación detrás de los servicios que ofrece LKS, nuestra experiencia y la trayectoria que hemos construido por más de 50 años.',
      en: 'Discover the motivation behind the services we offer LKS, our experience and the history we have built for more than 50 years.'
    }
  },
  homeButton: {
    text: {
      es: 'CONOCE MÁS',
      en: 'KNOW MORE'
    }
  },
  postsCardsText: {
    title: {
      es: 'El último movimiento',
      en: 'The last movement'
    }
  },
  bottomText: {
    title: {
      es: 'Con calidad, seguridad e innovación, respaldamos su prestigio',
      en: 'With quality, safety and innovation we support your prestige'
    }
  }
}

export default homepageText
