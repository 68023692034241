import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import GeneralContext from '../../general/generalContext/GeneralContext'

const NewsPrincipal = ({ cover }: any) => {
  const { language } = useContext(GeneralContext)
  const history = useHistory()

  const getPublish = (ruta: string) => {
    history.push(ruta)
  }

  return (
    <div className="w-11/12 lg:w-10/12  container m-auto grid grid-cols-card gap-12 pt-[60px] relative">
      {cover.map((card: any) => {
        return (
          <div
            key={card.id}
            className="w-full  font-segoe flex flex-col justify-center rounded-lg mb-5 hover:scale-105 transition duration-300 cursor-pointer"
            style={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}
            onClick={() => getPublish(`/publicaciones/${card.id}`)}
          >
            <img
              src={card.image}
              className="object-cover rounded-t-lg"
              alt={card.title[language]}
            />
            <div className=" flex flex-col mx-4 my-5 space-y-1 tracking-widest w-10/12">
              <span className=" font-segoe text-[16px] font-normal tracking-[0.04em]">
                {card.date[language]}
              </span>
              <span className="font-segoe text-[20px] font-bold tracking-[0.15em]">
                {card.title[language]}
              </span>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default NewsPrincipal
