type innovation = {
  title: { es: string; en: string } | string | any
  firstParagraph: { es: string; en: string } | string | any
  secondParagraph: { es: string; en: string } | string | any
  thirdParagraph?: { es: string; en: string } | string | any
  processTitle: { es: string; en: string } | string | any
  processText: { es: string; en: string } | string | any
  titleMandatory: { es: string; en: string } | string | any
  commandments: { es: string; en: string }[] | string[] | any
  lastMovementTitle: { es: string; en: string } | string | any
}

export const textInnovation: innovation = {
  title: {
    es: 'Innovación',
    en: 'Innovation'
  },
  firstParagraph: {
    es: ' Crear e implementar un sistema de innovación es valioso para cualquier organización, desarrollar proyectos innovadores han marcado la diferencia en las compañías durante los últimos años.',
    en: ' Creating and implementing an innovation system is valuable for any organization, developing innovative projects have marked the difference in the companies during the last years.'
  },
  secondParagraph: {
    es: 'Innovamos porque deseamos cumplir con las expectativas de nuestros clientes quienes buscan el mejor servicio y nos motivan a estar siempre en movimiento',
    en: 'We innovate because we want to meet the expectations of our clients who seek the best service and motivate us to always be moving'
  },
  thirdParagraph: {
    es: 'La MISIÓN y VISIÓN de la compañía apuntan a convertirnos en la empresa más innovadora a nivel nacional en el área del mantenimiento industrial y para lograr nuestro objetivo hemos desarrollado un sistema de innovación que junto al sistema de calidad nos acerca cada día más a nuestras metas.',
    en: 'The mission and vision of the company points to convert us into the most innovative company in the national maintenance industry and to achieve our goal we have developed a system of innovation that along with the quality system we are approaching each day more to our goals.'
  },

  processTitle: {
    es: 'Proceso de innovación',
    en: 'Innovation process'
  },
  processText: {
    es: 'En LKS implementamos innovación basándonos en nuestro sistema de innovación. Cada etapa del proceso trae consigo actividades internas que ayudan a generar fragmentos de ideas, combinarlas, crear portafolios, validar un proyecto priorizarlo e impulsarlo a la realidad.',
    en: 'In LKS we implement innovation based on our innovation system. Each stage of the process brings with it internal activities that help generate fragments of ideas, combine them, create portfolios, validate a project prioritize it and push it to reality.'
  },
  titleMandatory: {
    es: 'Nuestros mandamientos a la hora de innovar',
    en: 'Our commitments when innovating'
  },
  commandments: [
    {
      es: 'Respetar el prestigio y la calidad de nuestros servicios para los clientes',
      en: 'Respect the prestige and quality of our services to our customers'
    },
    {
      es: 'Tener ética en la prestación del servicio y en el manejo de la información',
      en: 'Have ethics in the provision of service and in the handling of information'
    },

    {
      es: 'En la presentación de una idea primero agradece',
      en: 'In the presentation of an idea first thanks'
    },
    {
      es: 'Optimizar costos con tus propios recursos ',
      en: 'Optimize costs with your own resources'
    },
    {
      es: 'Involucrar al personal en los proyectos de innovación de la empresa',
      en: 'Involve the personnel in the innovation projects of the company'
    },
    {
      es: 'Tu idea de innovación siempre irá en pro a la protección del medio ambiente',
      en: 'Your innovation idea will always go in pro to protecting the environment'
    },
    {
      es: 'Un día seguro será un buen día',
      en: 'A day safe will be a good day'
    },
    {
      es: 'Nunca estarás solo para realizar un proyecto ',
      en: 'Never be alone to do a project'
    },
    {
      es: 'Crear cultura de innovación capacitando y certificando a los empleados',
      en: 'Create culture of innovation training and certifying employees'
    }
  ],
  lastMovementTitle: {
    es: 'EL ÚLTIMO MOVIMIENTO INNOVANDO',
    en: 'THE LAST MOVEMENT INNOVATING'
  }
}
