import firebase from 'firebase'

// Producción
// const config = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID
// };

// Pruebas
const config = {
  apiKey: 'AIzaSyBJcB-r_T9j9JgpkrXSD8SwfW5Bsw2Itwk',
  authDomain: 'lks-landing.firebaseapp.com',
  projectId: 'lks-landing',
  storageBucket: 'lks-landing.appspot.com',
  messagingSenderId: '334099059925',
  appId: '1:334099059925:web:6b8f57cf33e97953617e5c',
  measurementId: 'G-ZDJWGWSDNC'
}

firebase.initializeApp(config)
firebase.analytics()

export default firebase
