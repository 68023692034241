import React from 'react'
import principalPhoto from '../../../assets/images/security/firstImageSection.png'
import Banner from '../../general/banner/Banner'
import bannerSecurity from '../../../assets/images/security/portadaSeguridad.png'
import { firstSection } from '../data'
import { GeneralContextInterface } from '../../general/generalContext/GeneralContext'

const Intro = ({ language }: GeneralContextInterface | any) => {
  return (
    <div>
      <Banner bgImage={bannerSecurity} title={firstSection.title[language]} />

      <section className="lg:py-[60px] w-full">
        <div className="w-10/12 flex flex-col justify-center items-center m-auto space-y-4 pt-14">
          <div className="flex flex-col gap-4">
            <h2 className="text-[23px] lg:text-[30px] tracking-[0.15em] font-town font-semibold text-left uppercase">
              {firstSection.headingTwo[language]}
            </h2>
            <p className="max-w-5xl  text-[15px] md:text-[15px] lg:text-[16px] tracking-[0.04em] lg:leading-[29px] lead text-left font-segoe font-light w-full pb-[30px]">
              {firstSection.paragraph[language]}
            </p>
          </div>
          <img
            src={principalPhoto}
            className="rounded-lg w-full lg:w-[85%] "
            alt="foto"
          />
        </div>
      </section>
    </div>
  )
}

export default Intro
