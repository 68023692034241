import experienceOne from '../../../assets/images/prestige/experiencia/1.jpeg'
import experienceTwo from '../../../assets/images/prestige/experiencia/2.jpeg'
import experienceThree from '../../../assets/images/prestige/experiencia/3.jpeg'

// CertificatesPrestige

import certificateOne from '../../../assets/images/prestige/certificaciones/1.png'
import certificateTwo from '../../../assets/images/prestige/certificaciones/2.png'
import certificateThree from '../../../assets/images/prestige/certificaciones/3.png'
import certificateFour from '../../../assets/images/prestige/certificaciones/4.png'
import certificateFive from '../../../assets/images/prestige/certificaciones/5.png'
import certificateSix from '../../../assets/images/prestige/certificaciones/6.png'

// Photo testimonials

import carboqui from '../../../assets/images/prestige/testimonials/carboqui.jpg'
import knowledge from '../../../assets/images/prestige/testimonials/yara-1.svg'

// FirstPrestigeSection

interface prestigeBanner {
  title: { es: string; en: string } | string | any
}

export const titlePrestige: prestigeBanner = {
  title: {
    es: 'Prestigio',
    en: 'Prestige'
  }
}

interface IFirstPrestige {
  headline?: { es: string; en: string } | string | any
  paragraph?: { es: string; en: string } | string | any
  info: {
    id: number
    image: string
    headlineText: string
    text: { es: string; en: string } | string | any
  }[]
}

export const firstPrestige: IFirstPrestige = {
  headline: {
    es: 'Experiencia',
    en: 'Experience'
  },
  paragraph: {
    es: 'Somos una compañía en mejoramiento continuo y orientada a soluciones, nos enfocamos en la confiabilidad operativa de nuestros clientes para asegurar su producción y prestigio.',
    en: 'We are a continuous improvement company oriented to solutions, we focus on the reliability of our customers to ensure their production and prestige.'
  },
  info: [
    {
      id: 1,
      image: experienceOne,
      headlineText: 'Ad eos saepe lucilius',
      text: {
        es: 'Pactos por la innovación - Sistemas de innovación con la Cámara de Comercio de Cartagena, IXL Center y MINCIENCIAS',
        en: 'Pacts for innovation - Innovation systems with the Cartagena Chamber of Commerce, IXL Center and MINCIENCIAS'
      }
    },
    {
      id: 2,
      image: experienceTwo,
      headlineText: 'Ad eos saepe lucilius',
      text: {
        es: 'INNOVA CLUSTER con empresas del competitivo sector de mantenimiento de Cartagena',
        en: 'INNOVA CLUSTER with companies in the competitive maintenance sector of Cartagena'
      }
    },
    {
      id: 3,
      image: experienceThree,
      headlineText: 'Ad eos saepe lucilius',
      text: {
        es: 'Capacitación con WEG en los laboratorios de su sede en Jaragua do Sud, Brasil',
        en: 'Training with WEG in the laboratories of its headquarters in Jaragua do Sud, Brazil'
      }
    }
  ]
}

// CertificatesPrestige

export const textCertificate: { es: string; en: string } | string | any = {
  es: 'Certificaciones',
  en: 'Certifications'
}

export const certificatesLks = [
  {
    id: 1,
    certificate: certificateOne
  },
  {
    id: 2,
    certificate: certificateTwo
  },
  {
    id: 3,
    certificate: certificateThree
  },
  {
    id: 4,
    certificate: certificateFour
  },
  {
    id: 5,
    certificate: certificateFive
  },
  {
    id: 6,
    certificate: certificateSix
  }
]
// Testimonios

interface ITestimonials {
  id: number
  image: string
  name: string
  company: string
  text: { es: string; en: string } | string | any
}

type testimonialLKS = {
  title: { es: string; en: string } | string | any
  testimonials: ITestimonials[]
}

export const testimonialsLks: testimonialLKS = {
  title: {
    es: 'Testimonios',
    en: 'Testimonials'
  },
  testimonials: [
    {
      id: 1,
      image: carboqui,
      name: 'Felipe Cabrera',
      company: 'Carboquimica S.A',
      text: {
        es: 'Contamos siempre el apoyo y respaldo incondicional de unos de sus mejores aliados en el área de mantenimiento, dándonos confiabilidad y calidad en los trabajos, LKS representa una parte importante para nuestra operación, brindando apoyo y asesorías  24/7, 365 días del año. Gracias a lks,  tenemos fichas y datos técnicos con trazabilidad de nuestros equipos que nos permiten confiar cada día más en su labor.',
        en: 'We always rely on the support and support of our best partners in the maintenance area, giving us reliability and quality in the works, LKS represents a part of our operation, providing support and advice 24/7, 365 days a year. Thanks to lks, we have data sheets and technical data with traceability of our equipment that allows us to trust every day more in their work.'
      }
    },
    {
      id: 3,
      image: knowledge,
      name: 'William Ruiz',
      company: 'Yara',
      text: {
        es: 'Muy buen protocolo de recepción y entrega de equipos, LKS me ha ofrecido un excelente soporte técnico y de atención podría decirle que es casi 24/7,  la confiabilidad en sus intervenciones está respaldada por la documentación que hacen en sus informes preliminares y finales.',
        en: 'Very good reception and delivery of equipment, LKS has provided a good technical support and a good service could say that it is almost 24/7, the reliability of their interventions is supported by the documentation they make in their preliminary and final reports.'
      }
    }
  ]
}
