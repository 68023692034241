import { useContext, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import IconCross from '../../../assets/cross.svg'
import GeneralContext from '../generalContext/GeneralContext'
import { menuData } from './data'

const Menu = ({ open }: { open: boolean }) => {
  const { toggleMenu, language } = useContext(GeneralContext)

  const [actualTab, setActualTab] = useState<number>(0)

  const menuRef = useRef<HTMLDivElement>(null)

  const history = useHistory()

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        toggleMenu(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [menuRef])

  const handleClick = (path: string): void => {
    history.push(path)
    toggleMenu(false)
  }

  return (
    <div
      className="fixed top-0 left-0 h-full w-full 500px:w-[500px] bg-white flex flex-col z-50 transition-all overflow-x-hidden font-segoe"
      style={open ? { left: '0' } : { left: '-110%' }}
      ref={menuRef}
    >
      <div
        className="rounded-bl-[9px] bg-[#C4C4C4] w-[66px] h-[61px] min-h-[61px] self-end flex items-center justify-center cursor-pointer invert-img"
        onClick={() => toggleMenu(false)}
      >
        <img src={IconCross} alt="Close" />
      </div>
      <div className="flex lg:flex-col lg:items-start pt-[52px] pl-[40px]">
        <span
          className={
            !actualTab
              ? 'tab-selected-primary sm:text-xl mr-[32px] lg:menu-tab-selected-lg'
              : 'tab-primary sm:text-xl mr-[32px] lg:menu-tab-lg'
          }
          onClick={() => {
            if (window.innerWidth < 1024) setActualTab(0)
          }}
        >
          {menuData.menu[language]}
        </span>
        <span
          className={
            actualTab
              ? 'tab-selected-primary sm:text-xl lg:menu-tab-selected-lg lg:font-normal'
              : 'tab-primary sm:text-xl lg:menu-tab-lg lg:font-normal'
          }
          onClick={() => {
            if (window.innerWidth < 1024) setActualTab(1)
          }}
        >
          {menuData.whyUs[language]}
        </span>
      </div>
      <div
        className={
          actualTab === 0
            ? 'flex flex-col w-full pl-10 pr-5 pt-24 lg:hidden'
            : 'hidden flex-col w-full pl-10 pr-5 pt-24 lg:hidden'
        }
      >
        <span
          className={
            location.pathname !== '/' ? 'option-menu' : 'option-menu-selected'
          }
          onClick={() => handleClick('/')}
        >
          {menuData.home[language]}
        </span>
        <span
          className={
            location.pathname !== '/que-hacemos'
              ? 'option-menu'
              : 'option-menu-selected'
          }
          onClick={() => handleClick('/que-hacemos')}
        >
          {menuData.whatWeDo[language]}
        </span>
        <span
          className={
            location.pathname !== '/nuestra-empresa'
              ? 'option-menu'
              : 'option-menu-selected'
          }
          onClick={() => handleClick('/nuestra-empresa')}
        >
          {menuData.ourMotor[language]}
        </span>
        <span
          className={
            location.pathname !== '/publicaciones'
              ? 'border-transparent option-menu'
              : 'border-transparent option-menu-selected'
          }
          onClick={() => handleClick('/publicaciones')}
        >
          {menuData.posts[language]}
        </span>
      </div>
      <div
        className={
          actualTab === 1
            ? 'flex flex-col w-full pl-10 pr-5 pt-9 lg:pt-2'
            : 'hidden flex-col w-full pl-10 pr-5 pt-9 lg:pt-2 lg:flex'
        }
      >
        <p className="text-[14px] tracking-[0.07em]">
          {menuData.knowLKS[language]}
        </p>
        <span
          onClick={() => handleClick('/calidad')}
          className={
            location.pathname !== '/calidad'
              ? 'option-menu pt-10'
              : 'option-menu-selected pt-10'
          }
        >
          {menuData.quality[language]}
        </span>
        <span
          className={
            location.pathname !== '/seguridad'
              ? 'option-menu'
              : 'option-menu-selected'
          }
          onClick={() => handleClick('/seguridad')}
        >
          {menuData.security[language]}
        </span>
        <span
          className={
            location.pathname !== '/prestigio'
              ? 'option-menu'
              : 'option-menu-selected'
          }
          onClick={() => handleClick('/prestigio')}
        >
          {menuData.prestige[language]}
        </span>
        <span
          className={
            location.pathname !== '/innovacion'
              ? 'option-menu border-transparent'
              : 'option-menu-selected border-transparent'
          }
          onClick={() => handleClick('/innovacion')}
        >
          {menuData.innovation[language]}
        </span>
      </div>
    </div>
  )
}

export default Menu
