const BottomBanner = ({ title }: any) => {
  return (
    <div
      className="w-full py-[86px] lg:py-[120px] bg-center bg-cover flex items-center justify-center mt-9"
      style={{ backgroundColor: `#0016B5` }}
    >
      <h2 className="font-town font-bold text-[23px] sm:text-[30px] lg:text-[46px] leading-8 sm:leading-[40px] lg:leading-[55px] tracking-[0.15em] text-center text-white w-[355px] sm:w-[600px] lg:w-[883px]">
        {title}
      </h2>
    </div>
  )
}

export default BottomBanner
