import { useContext } from 'react'
import HomepageBanner from '../../assets/images/homepage/homepageBanner.jpg'
import GeneralContext from '../general/generalContext/GeneralContext'
import homepageText from './homepageText'

const text: any = homepageText.homeBanner

const HomeBanner = () => {
  const { toggleContact, language } = useContext(GeneralContext)

  return (
    <div
      className="pt-[87px] lg:pt-[155px] w-full pb-[82px] lg:pb-[158px] text-white bg-center bg-cover"
      style={{ backgroundImage: 'url("' + HomepageBanner + '")' }}
    >
      <div className="max-w-[1500px] mx-auto flex flex-col items-center">
        <h1 className="mt-[61px] sm:mt-[185px] lg:mt-[132px] font-town font-black text-[28px] sm:text-[46px] tracking-[0.15em] px-16 text-center leading-8 sm:leading-[56px]">
          {text.title[language]}
        </h1>
        <p className="mt-8 lg:h-[95px] sm:mt-[70px] lg:mt-[40px] px-7 sm:px-[114px] lg:px-[28%] font-segoe text-[15px] lg:text-[16px] tracking-[0.03em] sm:tracking-[0.08em] text-center font-light sm:font-normal leading-[23px] sm:leading-[31px]">
          {text.text[language]}
        </p>
        <div className="font-town mt-[53px] text-[15px] lg:text-xl sm:mt-[121px] mb-14 sm:mb-[143px] lg:mb-0 tracking-[0.15em]">
          <span>#SIEMPRE</span>
          <span className="font-light">EN</span>
          <span>MOVIMIENTO</span>
        </div>
        <span
          className="btn-primary border-white hover:bg-white hover:text-black lg:self-end lg:mr-[11%] lg:-mt-10"
          onClick={() => toggleContact(true)}
        >
          {text.button[language]}
        </span>
      </div>
    </div>
  )
}

export default HomeBanner
