import img60 from './../../assets/images/ourCompany/history/60.jpg'
import img601 from './../../assets/images/ourCompany/history/601.jpg'
import img70 from './../../assets/images/ourCompany/history/70.jpg'
import img701 from './../../assets/images/ourCompany/history/701.jpg'
import img80 from './../../assets/images/ourCompany/history/80.jpg'
import img801 from './../../assets/images/ourCompany/history/801.jpg'
import img90 from './../../assets/images/ourCompany/history/90.jpg'
import img901 from './../../assets/images/ourCompany/history/901.jpg'
import img2000 from './../../assets/images/ourCompany/history/2000.jpg'
import img2005 from './../../assets/images/ourCompany/history/2005.jpg'
import img2010 from './../../assets/images/ourCompany/history/2010.jpeg'
import img2016 from './../../assets/images/ourCompany/history/2016.jpg'
import img2020 from './../../assets/images/ourCompany/history/2020.jpg'

// type CardEvent = {
//   img: string
//   text: {
//     es: string
//     en: string
//   }
// }

// type EventHistory = {
//   title: string
//   cards: CardEvent[]
// }

const events: any = [
  {
    title: "60'",
    cards: [
      {
        img: img60,
        text: {
          es: 'LKS es una empresa colombiana fundada en 1961 por Luis Kurmen Solórzano en la ciudad de Bogotá. ',
          en: 'LKS is a Colombian company founded in 1961 by Luis Kurmen Solórzano in Bogotá.'
        }
      },
      {
        img: img601,
        text: {
          es: 'Se crea un taller de soporte de servicio para SIEMENS COLOMBIANA con la sociedad de Luis Kurmen (Colombia) y Helmut Schild (Alemania) ',
          en: 'A workshop is created for Siemens Colombia with the company of Luis Kurmen (Colombia) and Helmut Schild (Germany)'
        }
      }
    ]
  },
  {
    title: "70'",
    cards: [
      {
        img: img70,
        text: {
          es: 'Desde el fortalecimiento de la industria manufacturera en Colombia LKS ha creado alianzas con los fabricantes de motores y generadores y equipos eléctricos rotativos para respaldar la producción de las empresas de bienes y servicios en el país. ',
          en: 'From the strengthening of the manufacturing industry in Colombia LKS has created alliances with manufacturers of motors and generators and rotating equipment to support the production of the businesses and services in the country.'
        }
      },
      {
        img: img701,
        text: {
          es: 'LKS empieza a recibir en sus instalaciones equipos de todas las capacidades, y la adquisición de maquinaria especializada para trabajos mecánicos y eléctricos. ',
          en: 'LKS starts to receive equipment of all capacities, and the acquisition of specialized machinery for mechanical and electric work.'
        }
      }
    ]
  },
  {
    title: "80'",
    cards: [
      {
        img: img80,
        text: {
          es: 'Desde el año 1980 LKS abrió sus puertas a nivel internacional para ser servicio técnico autorizado para, general electric, toshiba y US electrical motors. ',
          en: 'From the year 1980 LKS opened its doors to international level to be authorized service technical for, general electric, toshiba and US electrical motors.'
        }
      },
      {
        img: img801,
        text: {
          es: 'LKS recibe asesoría técnica y capacitación para sus ingenieros del doctor Bernhard Tesch (Alemania)',
          en: 'LKS receives technical assistance and training for its engineers of the doctor Bernhard Tesch (Germany)'
        }
      }
    ]
  },
  {
    title: "90'",
    cards: [
      {
        img: img90,
        text: {
          es: 'La empresa adquiere nuevos compromisos con la reparación de motores y transformadores de 90MVA de la EPM de Medellín era la primera vez que se hacía este tipo de trabajo en Colombia y LKS había asumido ese reto.',
          en: 'The company acquired new commitments with the repair of motors and transformers of 90MVA of the EPM of Medellín was the first time that this type of work was made in Colombia and LKS assumed that challenge.'
        }
      },
      {
        img: img901,
        text: {
          es: 'Empezamos una nueva etapa de adquisición de equipos de última tecnología para pruebas de transformadores. Fue toda una década de inversión para la compañía.',
          en: 'We started a new stage of acquisition of equipment of the last technology for transformer tests. It was all a decade of investment for the company.'
        }
      }
    ]
  },
  {
    title: '2000',
    cards: [
      {
        img: img2000,
        text: {
          es: 'En este año el fundador hace entrega de la dirección de la empresa a sus herederos y esta se divide en Motores - Generadores LKS y Transformadores LKS ',
          en: 'In this year the founder makes delivery of the company to its heirs and this is divided into motors - generators LKS and transformers LKS'
        }
      }
    ]
  },
  {
    title: '2005',
    cards: [
      {
        img: img2005,
        text: {
          es: 'En el 2008 recibimos nuestras primeras certificaciones en calidad y seguridad, fue una etapa de transformación y formalización de procesos.',
          en: 'In 2008 we received our first quality and safety certificates, it was a transformation and formalization of processes.'
        }
      }
    ]
  },
  {
    title: '2010',
    cards: [
      {
        img: img2010,
        text: {
          es: 'Obtuvimos la primera clasificación del consejo nacional de seguridad. Todos los proyectos y  actividades realizadas por LKS fueron respaldadas por un sistema sólido que fomentará una cultura sana dentro de nuestra organización',
          en: 'We obtained the first classification of the national security council. All the projects and activities performed by LKS were supported by a solid system that will foster a healthy culture within our organization'
        }
      }
    ]
  },
  {
    title: '2016',
    cards: [
      {
        img: img2016,
        text: {
          es: 'Abrimos una sede en la ciudad de Cartagena, Colombia para atender la demanda de la región caribe tanto nacional, como internacionalmente. Contribuimos con la creación de más de 20 empleos y oportunidades para las personas de la región.',
          en: 'We opened a branch in Cartagena, Colombia to serve the demand of the region both national and internationally. We contribute with the creation of more than 20 jobs and opportunities for people of the region.'
        }
      }
    ]
  },
  {
    title: '2020',
    cards: [
      {
        img: img2020,
        text: {
          es: 'El COVID-19 llega al mundo para acelerar la transformación digital y tecnológica de las empresas y LKS no fue la excepción. Los servicios que ofrecemos pertenecen a la cadena de servicios esenciales lo que implicó estar siempre en movimiento atendiendo las urgencias y mantenimientos de los equipos para la producción de bienes y servicios indispensables en nuestra sociedad. ',
          en: 'The COVID-19 comes to the world to accelerate the digital transformation and technological transformation of the companies and LKS was not the exception. Our services belong to the essential chain of services that implied always moving to respond to the emergencies and maintenance of the equipment for the production of essential goods and services in our society.'
        }
      }
    ]
  }
]

export default events
