import React from 'react'
import { GeneralContextInterface } from '../../general/generalContext/GeneralContext'
import { textHSQ, textMision, textVision } from '../data'

const MisionVision = ({ language }: GeneralContextInterface | any) => {
  return (
    <section className="w-full">
      <div className="w-full bg-white py-16 ">
        <div className="container m-auto flex flex-col justify-between items-center lg:flex-row gap-10 w-11/12">
          <div className="w-[95%]">
            <img
              className=" rounded-lg shadow bg-none bg-gray-800 bg-contain bg-center bg-no-repeat w-11/12 lg:max-w-[467px] h-[200px] sm:h-[400px]  lg:h-[422px] object-cover"
              src={textMision.image}
              alt={textMision.title?.[language]}
            />
          </div>
          <div className="space-y-9 w-[95%] lg:w-full lg:ml-5">
            <h3 className="text-left w-full text-2xl lg:text-3xl font-bold tracking-widest uppercase">
              {textMision.title?.[language]}
            </h3>
            <p className="text-left w-full lg:w-10/12 lg:ml-8 tracking-wider font-thin leading-7">
              {textMision.paragraph?.[language]}
            </p>
          </div>
        </div>
      </div>
      <div className="w-full mt-14 py-16">
        <div className="w-11/12 container m-auto flex flex-col-reverse justify-center items-center lg:flex-row sm:gap-10">
          <div className="space-y-9 w-[95%] lg:w-full lg:ml-5 mt-7">
            <h3 className="text-left w-full text-2xl lg:text-3xl font-bold tracking-widest uppercase">
              {textVision.title?.[language]}
            </h3>
            <p className="text-left w-full lg:w-10/12 lg:ml-8 tracking-wider font-thin leading-7">
              {textVision.paragraph?.[language]}
            </p>
          </div>
          <div className="w-[95%]">
            <img
              className="  rounded-lg shadow bg-none bg-gray-800 bg-contain bg-center bg-no-repeat w-11/12 lg:max-w-[467px]  lg:h-[422px] object-cover"
              src={textVision.image}
              alt={textVision.title?.[language]}
            />
          </div>
        </div>
      </div>
      <div className="w-full bg-white mt-14 py-16">
        <div className="w-11/12  container m-auto flex flex-col justify-center items-center lg:flex-row gap-10 ">
          <div className="w-[95%]">
            <img
              className=" rounded-lg shadow  bg-contain bg-center bg-no-repeat lg:max-w-[467px]  lg:h-[422px] object-cover"
              src={textHSQ.image}
              alt={textHSQ.title?.[language]}
            />
          </div>
          <div className="space-y-9 w-[95%] lg:w-full lg:ml-5">
            <h3 className="text-left lg:text-left w-full text-2xl lg:text-3xl font-bold tracking-widest uppercase">
              {textHSQ.title?.[language]}
            </h3>
            <p className="text-left lg:text-left w-full lg:w-10/12 lg:ml-8 tracking-wider font-thin leading-7">
              {textHSQ.paragraph?.[language]}
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MisionVision
